import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

interface DashboardMainLayoutProps {
  children?: ReactNode;
}

export const DashboardMainLayout: FC<DashboardMainLayoutProps> = (props) => {
  const { children } = props;
  return (
    <Box
      component="main"
      className="flex-direction-column-md"
      sx={{
        flexGrow: 2,
        width: "-webkit-fill-available",
        overflow: " hidden",
        position: "relative",
        display: "flex",
        // marginTop: "6px",
      }}
    >
      {children}
      
    </Box>
  );
};
