/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddSongBackground } from "../../pngs";
import { SearchComponents } from "../../components/filter/search-component";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useGetArtistListQuery } from "../../components/apis/artistsApi";
import { ROUTES } from "../../components/consts/routes.consts";
import { useLocation, useNavigate } from "react-router-dom";
import { GreenDot } from "../../svgs";
import { themeColors, themeFonts } from "../../configs";

export const SubmitSongPage = () => {
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const location = useLocation();
  const userName = location.state?.userName;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const track = "https://open.spotify.com/track";
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState<string>("");
  const parts = searchInput.split("/");
  const lastPart = parts[parts.length - 1];
  const [trackId, queryString] = lastPart.split("?");
  const { refetch } = useGetArtistListQuery<any>(
    { trackId: trackId },

    {
      skip: !searchInput.includes(track),
    }
  );
  const handleConnect = async () => {
    if (userName) {
      // window.location.href = `http://localhost:9090/api/auth/login?trackId=${trackId}&curator=${userName}`;
      // window.location.href = `https://pitch-playlist-api.vercel.app/api/auth/login?trackId=${trackId}&curator=${userName}`;
      window.location.href = `https://api.pitchplaylists.com/api/auth/login?trackId=${trackId}&curator=${userName}`;
    } else {
      // window.location.href = `http://localhost:9090/api/auth/login?trackId=${trackId}`;
      // window.location.href = `https://pitch-playlist-api.vercel.app/api/auth/login?trackId=${trackId}`;
      window.location.href = `https://api.pitchplaylists.com/api/auth/login?trackId=${trackId}`;
    }
  };
  const handleSearchChange = (value: React.SetStateAction<string>) => {
    setSearchInput(value);
  };
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (searchInput.includes(track)) {
      if (token) {
        refetch({ trackId: trackId }).then((response: any) => {
          if (response.status === "fulfilled") {
            navigate(ROUTES.SUBMIT_A_SONG_1, {
              state: { trackData: response.data, curatorName: userName },
            });
          } else {
            navigate(ROUTES.PAGE_NOT_FOUND);
          }
        });
      } else {
        handleConnect();
      }
    } else {
      setTimeout(() => {
        setSearchInput("");
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, searchInput]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isInvalid = searchInput !== "" && !searchInput.includes(track);
  return (
    <Grid
      sx={{
        background: `url(${AddSongBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        marginTop: "-80px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            maxWidth: "478px",
            width: "100%",
            textAlign: "center",
            padding: "0 15px",
          }}
        >
          <Typography
            sx={{
              fontSize: isMiniScreen ? "28px" : "36px",
              fontWeight: "600",
              color: "#ffffff",
              marginBottom: "20px",
            }}
          >
            Select your song
          </Typography>
          <SearchComponents
            searchTitle={"Insert your Spotify track link"}
            isEmpty={true}
            onSearchChange={handleSearchChange}
            value={searchInput}
            isInvalid={isInvalid}
          />
          <Button
            className="need-help-btn"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#fff",
              marginTop: "16px",
              textAlign: "end  !important",
              cursor: "pointer",
              background: "transparent",
              padding: "0px",
              height: "auto",
              display: "flex",
              marginLeft: "auto",
            }}
          >
            Need Help?
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPopover-paper": {
                boxShadow: "4px 4px 4px 0px #0000001A",
              },
            }}
          >
            <Box
              sx={{
                padding: "12px",
                minWidth: "251px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "70px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: themeFonts["Poppins-Bold"],
                    fontSize: "16px !important",
                    color: "#000000",
                  }}
                >
                  Using Spotify Track Links
                </Typography>
                <Box
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  <CloseIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  ></CloseIcon>
                </Box>
              </Box>
              <Box>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    paddingTop: "8px !important",
                    paddingBottom: "3px !important",
                  }}
                >
                  <ListItem
                    sx={{
                      padding: "0px 0px 16px 0px !important",
                    }}
                  >
                    <ListItemText
                      sx={{
                        "& span.MuiListItemText-primary": {
                          lineHeight: "15px",
                          display: "flex",
                          fontSize: "12px",
                          color: themeColors["#00000080"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          alignItems: "center",
                        },
                      }}
                      primary={` 1.  Go to your track on Spotify`}
                    />
                  </ListItem>

                  <ListItem
                    sx={{
                      padding: "0px 0px 16px 0px !important",
                    }}
                  >
                    <ListItemText
                      sx={{
                        "& span.MuiListItemText-primary": {
                          lineHeight: "15px",
                          display: "flex",
                          fontSize: "12px",
                          color: themeColors["#00000080"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          alignItems: "center",
                          gap: "5px",
                        },
                      }}
                    >
                      2. Click the{" "}
                      <GreenDot
                        style={{
                          boxSizing: "content-box",
                          cursor: "pointer",
                        }}
                      />
                      icon
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    sx={{
                      padding: "0px 0px 16px 0px !important",
                    }}
                  >
                    <ListItemText
                      sx={{
                        "& span.MuiListItemText-primary": {
                          lineHeight: "15px",
                          display: "flex",
                          fontSize: "12px",
                          color: themeColors["#00000080"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          alignItems: "center",
                          gap: "5px",
                        },
                      }}
                    >
                      3. Select{" "}
                      <Typography
                        sx={{
                          fontSize: "12px",
                          lineHeight: "15px",
                          color: themeColors["#00ADB5"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          cursor: "pointer",
                        }}
                      >
                        Share
                      </Typography>{" "}
                      , then{" "}
                      <Typography
                        sx={{
                          fontSize: "12px",
                          lineHeight: "15px",
                          color: themeColors["#00ADB5"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          cursor: "pointer",
                        }}
                      >
                        copy song link
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    sx={{
                      padding: "0px !important",
                    }}
                  >
                    <ListItemText
                      sx={{
                        "& span.MuiListItemText-primary": {
                          lineHeight: "15px",
                          display: "flex",
                          fontSize: "12px",
                          color: themeColors["#00000080"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          alignItems: "center",
                        },
                      }}
                      primary={` 4. Paste in your link to our submission bar`}
                    />
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Grid>
  );
};
