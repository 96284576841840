import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Grid, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { themeFonts, themeColors } from "../../configs";
import { useDeleteUsersMutation } from "../../components/apis/userApi";
import { toast } from "react-toastify";
import { ConfirmationPopup } from "../../components/modals/confirmation-popup";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../components/consts/routes.consts";

export const Accounts = () => {
  const [value, setValue] = React.useState("general_setting");
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [hasOpen, setHasOpen] = useState(false);
  const [deleteItem] = useDeleteUsersMutation();
  const handleDelete = async (userId: any) => {
    try {
      const res = await deleteItem({ userId: userId }).unwrap();
      toast.success(res.message);
      setHasOpen(true);
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };
  useEffect(() => {
    if (hasOpen === true) {
      setTimeout(() => {
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("userId");
        window.localStorage.removeItem("userName");
        window.localStorage.removeItem("userEmail");
        window.localStorage.removeItem("userCredits");
        window.localStorage.removeItem("refresh_token");
        navigate(ROUTES.HOME);
      }, 1000);
    }
  }, [hasOpen, navigate]);
  const id = window.localStorage.getItem("id");

  return (
    <Grid
      container
      spacing={3}
      sx={{
        margin: "0px !important",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "24px !important",
        }}
      >
        <Box sx={{ width: "85%" }}>
          <Typography
            sx={{
              color: "#000000",
              fontSize: "28px",
              fontFamily: themeFonts["Poppins-Bold"],
            }}
          >
            Account Settings
          </Typography>

          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              sx={{
                "& span.MuiTabs-indicator": {
                  backgroundColor: themeColors["#00ADB5"],
                },
              }}
            >
              <Tab
                label="General Settings"
                value="general_setting"
                sx={{
                  fontSize: "16px",
                  fontFamily: themeFonts["Poppins-Regular"],
                  color: themeColors["#00000080"],
                  padding: "10px",
                  justifyContent: "flex-start",
                  textTransform: "capitalize",
                  "& svg path": { stroke: themeColors["#00000080"] },
                  "&.Mui-selected": {
                    color: themeColors["#00ADB5"],
                    fontFamily: themeFonts["Poppins-SemiBold"],
                    fontSize: "16px",
                  },
                }}
              />
            </TabList>
            <Box sx={{ border: 1, borderColor: "divider" }}>
              <TabPanel value="general_setting">
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#00000080",
                    fontFamily: themeFonts["Poppins-Regular"],
                  }}
                >
                  Here, you can delete your account along with all associated
                  data.
                </Typography>
                <Button
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    color: "#000000",
                    width: "175px",
                    padding: "0px",
                    height: "36px",
                    border: "2px solid #00ADB5",
                    borderRadius: "16px",
                    fontFamily: themeFonts["Poppins-Bold"],
                    marginTop: "16px",
                  }}
                  onClick={() => handleDelete({ userId: id })}
                >
                  Delete account
                </Button>
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Grid>
      {hasOpen && <ConfirmationPopup hasOpen={hasOpen} />}
    </Grid>
  );
};
