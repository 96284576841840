import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

interface UnauthMainLayoutProps {
  children?: ReactNode;
}

export const UnauthMainLayout: FC<UnauthMainLayoutProps> = (props) => {
  const { children } = props;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 2,
        width: "-webkit-fill-available",
        overflow: " hidden",
        position: "relative",
      }}
    >
      <Box sx={{ height:"74px"}}/>
      {children}
    </Box>
  );
};
