import { AppBar, Toolbar, useMediaQuery } from "@mui/material";

import { useState } from "react";
import { NavbarMenu } from "../../navbar";
import { themeColors } from "../../../configs";

export const AuthHeaderLayout = ({
  auth,
  isDashboardPage,
  isSubmitPage,
}: {
  auth?: any;
  isDashboardPage?: any;
  isSubmitPage?: any;
}) => {
  const [colorChange, setColorChange] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:1100px)");

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleConnect = async () => {
    // window.location.href = "http://localhost:9090/api/auth/login";
    // window.location.href =
    //   "https://pitch-playlist-api.vercel.app/api/auth/login";
    window.location.href =
    "https://api.pitchplaylists.com/api/auth/login";
  };
  return (
    <>
      <AppBar
        sx={{
          width: `auto`,
          left: "0px",
          backgroundColor: isDashboardPage
            ? themeColors["#FFFFFF"]
            : colorChange
            ? themeColors["#000000"]
            : "transparent",
          boxShadow: "none",
          position: "fixed",
          height: isSmallScreen ? "80px" : "80px !important",
        }}
      >
        <Toolbar
          sx={{
            height: isSmallScreen ? "80px !important" : "80px !important",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: isDashboardPage
              ? "1px solid #00000040"
              : "1px solid rgb(255 255 255 / 30%)",
            paddingX: isSmallScreen ? "16px !important" : "130px !important",
          }}
        >
          {isSmallScreen ? (
            <NavbarMenu
              isSmallScreen={isSmallScreen}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleConnect={handleConnect}
              auth={auth}
              isDashboardPage={isDashboardPage}
              isSubmitPage={isSubmitPage}
            />
          ) : (
            <NavbarMenu
              handleConnect={handleConnect}
              auth={auth}
              isDashboardPage={isDashboardPage}
              isSubmitPage={isSubmitPage}
            />
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
