import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { LogBackground3 } from "../pngs";
import { Footer } from "./footer";
import { Link } from "react-router-dom";

const FAQPage = () => {
  return (
    <Grid
      container
      spacing={3}
      sx={{
        margin: "0px !important",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <Box
        sx={{
          background: `url(${LogBackground3})`,
          backgroundRepeat: "no-repeat",
          textAlign: "center",
          marginTop: "-80px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            paddingTop: "80px",
          }}
        >
          <Typography
            className="faq-section-heading"
            sx={{
              fontSize: "36px",
              color: "#fff",
              fontWeight: "700",
              paddingY: "70px",
            }}
          >
            Frequently asked questions (FAQ)
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: "1650px",
          margin: "auto",
          width: "100%",
          padding: "0 15px",
        }}
      >
        <Typography className="faq-main-heading">
          How Does PitchPlaylists Work?
        </Typography>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            What is PitchPlaylists?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              PitchPlaylists is a platform for independent artists and curators.
              Artists can promote their music by submitting songs to Spotify
              curators.
            </Typography>
            <Typography className="faq-answers">
              Music curators can also utilize the website to expand their
              playlists by implementing specific gates for artists before
              accepting submissions.
            </Typography>
            <Typography className="faq-answers">
              Giving feedback on songs that appear on your curator dashboard can
              increase your chances of winning our weekly prize contest.
            </Typography>
            <Typography className="faq-answers">
              The winner receives a week of free promotion for their Spotify
              playlist through our Meta ad campaigns.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            Does PitchPlaylists cost?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              At PitchPlaylists, we believe in providing everyone with an equal
              opportunity to share their music.
            </Typography>
            <Typography className="faq-answers">
              That's why our platform is completely free for all users, offering
              you the chance to submit your tracks to up to 20 playlists every
              week. And the best part?
            </Typography>
            <Typography className="faq-answers">
              We refill your credits weekly, so you can keep sharing your music
              without any hassle. Just sign in and start receiving your free
              credits! But if you're eager to get your music out there even
              faster, we've got you covered.
            </Typography>
            <Typography className="faq-answers">
              You can choose to pitch your tracks to curators by purchasing more
              credits, giving you the chance to connect with even more
              listeners.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">How do I sign up?</Typography>
          <Box
            sx={{
              fontSize: "18px",
              lineHeight: "27px",
              fontWeight: "400",
              color: "#00000080",
            }}
          >
            <Typography className="faq-answers">
              You can link your Spotify account to PitchPlaylists by entering
              your song or playlist on our website. Alternatively, just click
              "Connect with Spotify" to sign in quickly.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            Do you store my Spotify login?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              No, all connections are made through Spotify's public API, and you
              can revoke access anytime in your Spotify settings. You can also
              delete your account easily through the Pitchplaylists account
              settings. The only information Pitchplaylists can see that is not
              publicly visible is your associated Spotify email, which is used
              for email campaigns and profile updates.
            </Typography>
            {/* <Typography className="faq-answers">
              please visit:
              <Link to="#" className="text-color-primary">
                spotify.com/settings
              </Link>
            </Typography> */}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            Does PitchPlaylists guarantee my music will get playlisted?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              The Platform itself doesn't make any editorial decisions regarding
              third-party playlists
            </Typography>
            <Typography className="faq-answers">
              The approval or rejection of a submission is solely at the
              discretion of the playlist owner you're submitting to.
              Pitchplaylists is dedicated to enhancing and streamlining the
              submission process to enhance the likelihood of your music being
              heard.{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            Who runs PitchPlaylists?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              PitchPlaylists is operated by an independent team of individuals
              organized within a company that has no affiliations with major
              record labels, publishers, or platforms.
            </Typography>
            <Typography className="faq-answers">
              The majority of the website's revenue is reinvested into weekly
              social media playlist promotion campaigns.{" "}
            </Typography>
          </Box>
        </Box>
        <Typography className="faq-main-heading">
          Submitting A Song & My Submissions
        </Typography>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            How do I submit my new release?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              Enter the Spotify track link on the homepage and follow the steps.{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            How many submissions can I send?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              Each week, you can submit your music to up to 20 playlists for
              free. Submitting from multiple accounts is prohibited. You have
              the option to buy extra credits to submit your track to additional
              playlists.{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">How do I sign up?</Typography>
          <Box>
            <Typography className="faq-answers">
              You can link your Spotify account to PitchPlaylists by entering
              your song or playlist on our website. Alternatively, just click
              "Connect with Spotify" to sign in quickly.{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            Can I submit music before release date?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              You can’t submit your music before it is released to Spotify. The
              earliest day is the release day of your song..{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            What if I want to submit an EP/Album or LP?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              You can only submit individual tracks right now.{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            What happens after I submit a song?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              Once you submit a song, it enters the playlist curator's queue for
              them to listen to. If they accept it, the song will be added
              directly to their playlist. You'll receive notifications via email
              and on your PitchPlaylists dashboard.{" "}
            </Typography>
          </Box>
        </Box>
        <Typography className="faq-main-heading">
          Submitting A Playlist & Submission Management
        </Typography>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            How do I add my playlist to PitchPlaylists?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              To submit a playlist, head to the homepage, click on "Submit,"
              then select "Submit a playlist," and follow the prompts. You can
              also add your playlist within the "For Curators" section on your
              Dashboard.{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            What are the requirements for my playlist to be added?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              To qualify as a curator on PitchPlaylists, your playlist must have
              a minimum of 100 followers and include at least 30 songs.{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            Is it possible for my playlist to be taken off your catalog?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              If we detect any suspicious activity using our bot detection tool,
              we'll promptly remove your playlist from our catalog.{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            How do I remove a playlist from PitchPlaylists?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              Head over to your dashboard and access the "For Curators" section.
              Under "Your Playlists," you'll find all your connected playlists
              listed. From there, you can easily select and remove them as
              needed.{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            What happens when I approve a song?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              The track is automatically added to the top of that playlist,
              saving you time and allowing for easy positioning when finding the
              song on Spotify.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            Can I give feedback to the artist?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              Currently, the only feedback option available is to click either
              the "Approve" or "Decline" buttons. This allows you to provide
              feedback effectively at this time.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            What Exclusive Deals do you offer?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              The best part is, you get free promotion for your playlist on
              social media. We'll create a catchy video ad and run a week-long
              campaign using Meta ads and Hypeddit. This proves that our
              playlists catalog is real and organic, so artists can trust
              they're getting genuine promotion.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            Is there a specific duration that an approved track must remain in
            the playlist?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              If you approve a track, it must stay in the playlist for the
              specified number of days shown in the submission box. Removing it
              sooner may result in warnings for your account, reducing your
              chances of being selected for the weekly playlist promotion prize.
            </Typography>
          </Box>
        </Box>
        <Typography className="faq-main-heading">
          Creating & Managing A Contest
        </Typography>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            How can a PitchPlaylists benefit me on Spotify?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              Curators who add their playlists to the PitchPlaylists catalog
              will have the option to include submission gates, such as
              following the playlist, saving tracks, or following your Spotify
              account. You can customize these parameters on your PitchPlaylists
              dashboard. By actively curating playlists, which includes
              providing feedback, sharing referral links with other aspiring
              PitchPlaylists curators, and maintaining genuine playlists, you
              stand a chance to win weekly free social media promotion. We will
              select the winner and contact you via email. You can then choose
              which playlist you'd like to promote, completely free of charge.
              There's no catch; the earnings that PitchPlaylists receives are
              primarily reinvested back into promoting your playlists through
              social media Meta ads.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            What criteria do you use to choose the curator winner?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              The selection process for choosing a curator winner is based on
              the Top Curator Chart section. The higher your ranking on this
              chart, the greater your chance of being selected. Criteria for
              selection include organic playlists (verified by Bot Detection &
              Playlist Data tool), activity rate, and submission amount on your
              account. Every aspect is considered important in determining the
              weekly prize winner.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            If I'm not ranked as the top curator on your chart, do I still stand
            a chance of being selected?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              Even if you're not the number one curator on our chart, you still
              have a chance to be chosen. We review all positions to ensure that
              curator activity and genuine playlists on our website receive
              valuable promotion.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            I was selected as the winner of the weekly promotion contest but
            haven't responded to your email. What should I do?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              If we don't receive any response from you through email we give a
              winner title to another curator until we get response email in 24
              hours after email is sent. That way we verify that you are truely
              active.
            </Typography>
          </Box>
        </Box>
        <Typography className="faq-main-heading">Account Issues:</Typography>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            I can't login to my account?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              Please check with Spotify as PitchPlaylists do not store any login
              information.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            How to delete my profile?
          </Typography>
          <Box>
            <Typography className="faq-answers">
              Disconnect your profile by revoking PitchPlaylists' access in your
              Spotify settings. Alternatively, within PitchPlaylists profile, go
              to "Account settings" in your account and delete your account
              easily. You can rejoin anytime.
            </Typography>
          </Box>
        </Box>

        {/* <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "52px",
          }}
        >
          <Typography className="faq-questions">
            My credits haven't renewed?
          </Typography>


          <Box>
            <Typography
              className="faq-answers"
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Contact us and we'll look into it. (Email:{" "}
              <Link to="#" className="text-color-primary">
                info@pitchplaylists.com
              </Link>
              ){" "}
            </Typography>
            <Typography
              className="faq-answers"
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              I want to report a bug with my account Please don't hesitate to
              reach out to{" "}
              <Link to="#" className="text-color-primary">
                info@pitchplaylists.com
              </Link>
              , including the following information:{" "}
            </Typography>
            <Typography
              className="faq-answers"
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              - Link to Spotify account associated with PitchPlaylists account{" "}
            </Typography>
            <Typography
              className="faq-answers"
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              - A link to the song in question (if applicable)
            </Typography>
            <Typography
              className="faq-answers"
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              - Any screenshots/recordings you can share with the team
            </Typography>
            <Typography
              className="faq-answers"
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              - Which browser and operating system you are using PitchPlaylists
              on{" "}
            </Typography>
            <Typography
              className="faq-answers"
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Please be aware that any form of other promotion, payola, or
              rule-breaking will lead to a ban!
            </Typography>
          </Box>

        </Box> */}
        <Box
          sx={{
            marginTop: "52px",
            paddingBottom: "72px",
            borderBottom: "0.5px solid #00000080",
          }}
        >
          <Typography className="faq-questions">
            I haven't received the credits I purchased!
          </Typography>
          <Box>
            <Typography
              className="faq-answers"
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Contact us and we'll look into it. (Email:{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>
              )
            </Typography>
          </Box>
        </Box>

        {/* <PurchaseCredit/> */}
      </Box>

      <Footer />
    </Grid>
  );
};

export default FAQPage;
