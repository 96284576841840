import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
// import { CalenderIcon1Svg } from "../../svgs";
import "../../assets/components/styles.css";
// import { CustomLabel } from "../label";
import { themeColors, themeFonts } from "../../configs";
import { FormHelperText } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { CalenderIcon } from "../../svgs";
import { useState } from "react";
import dayjs from "dayjs";

export const CustomDatePicker = ({
  label,
  width,
  placeholder,
  fontFamily,
  color,
  fontSize,
  format,
  value = "",
  onChange,
  name,
  helperText,
  minDate,
  disabled,
  maxDate,
  disablePast,
}: {
  label?: any;
  width?: any;
  placeholder?: any;
  fontFamily?: keyof typeof themeFonts;
  color?: any;
  fontSize?: any;
  format?: any;
  value?: any;
  onChange?: any;
  name?: any;
  helperText?: any;
  minDate?: any;
  disabled?: any;
  maxDate?: any;
  disablePast?: any;
}) => {
  const today = dayjs(); // Current date
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectValue, setSelectValue] = useState(value ?? null);

  const handleDateChange = (date: any) => {
    setSelectValue(date);

    if (onChange) onChange(date);
  };
  const shouldDisableDate = (date: any) => {
    return date.isAfter(today, "day");
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slots={{
          openPickerIcon: CalenderIcon,
        }}
        slotProps={{
          textField: {
            InputProps: {
              placeholder: `${placeholder ? placeholder : ""}`,
            },
          },
        }}
        onChange={handleDateChange}
        value={value}
        sx={{
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            borderRadius: "3px",
            fontSize: fontSize ? { fontSize } : "12px",
            height: "48px",
            fontFamily: { fontFamily },
            color: { color },
            padding: "12px",
          },
          "& input": {
            padding: "0px 8px",
            "&::placeholder": {
              // <----- Add this.
              opacity: 1,
            },
          },
          "& .MuiPickersFadeTransitionGroup-root": {
            position: "absolute !important",
            right: "117px",
          },
          "&.MuiFormControl-root.MuiTextField-root": {
            marginTop: "0px",
            width: width ? { width } : "100%",
          },
          "& .MuiIconButton-root": {
            marginRight: "2px",
          },
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "#00000080 !important",
            borderRadius: "32px",
          },
        }}
        showDaysOutsideCurrentMonth={true}
        format={`${format ? format : "DD/MM/YY"}`}
        disablePast={disablePast ? true : false}
        minDate={minDate}
        disabled={disabled}
        shouldDisableDate={shouldDisableDate}
        maxDate={maxDate ?? today}
      />
      {helperText && (
        <FormHelperText
          sx={{
            fontFamily: themeFonts["Poppins-Bold"],
            color: themeColors["#FF3939"],
            fontSize: 10,
            marginLeft: 0,
            position: "absolute",
            bottom: "-17px",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
};
