import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Tab,
  TextField,
  Typography,
  useMediaQuery,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  IconButton,
} from "@mui/material";
import { themeColors, themeFonts } from "../../configs";
import {
  CopyIcon,
  InfoIcon,
  LinkIcon,
  MusicIcon,
  MusicIcon2,
  PlayButtonIcon2,
} from "../../svgs";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useGetPlayListByIdQuery } from "../../components/apis/playlist";
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: "0px !important",
    marginBottom: "18px !important",
    boxShadow: "10px 10px 20px 0px #0000001A",
    border: "0.5px solid #00000033 !important",
    background: "#FFFFFF !important",
    color: "#000000",
    textAlign: "center",
    width: "267px",
    borderRadius: "0px",
    padding: "10px 10px 16px 10px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
    fontSize: "30px",
  },
}));

export const DashboardSidebarLayout = ({
  curatorValue,
  handleChange,
  curatorTab,
  handleCuratorTabChange,
}: {
  curatorValue?: any;
  handleChange?: any;
  curatorTab?: any;
  handleCuratorTabChange?: any;
}) => {
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const inputRef = useRef<HTMLInputElement>(null);
  const userId = localStorage.getItem("userId");
  const { data } = useGetPlayListByIdQuery({ userId });
  const copyToClipboard = () => {
    if (inputRef.current) {
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          toast.success("Copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  const id = window.localStorage.getItem("id");
  return (
    <Grid
      container
      spacing={3}
      className="maxwidth-300  flex-direction-column-md  tab-change-mobileview"
      sx={{
        margin: "0px !important",
        flexDirection: "column",
        width: "100% !important",
        maxWidth: "406px",
        borderRight: "1px solid #00000080",
        alignItems: "flex-end",
      }}
    >
      <Box
        sx={{
          paddingTop: "32px",
          paddingRight: isMiniScreen ? "0px" : "18px",
        }}
      >
        <TabContext value={curatorValue}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{
                border: "1px solid #00ADB5",
                borderRadius: "5px",
                width: "max-content",
                minHeight: "45px",
                "& span.MuiTabs-indicator": {
                  display: "none",
                },
                "& .MuiTabs-flexContainer": {
                  flexDirection: "row",
                  minHeight: "45px",
                },
              }}
            >
              <Tab
                label="For Artists"
                value="artists"
                sx={{
                  color: "#00ADB5",
                  borderRadius: "5px",
                  width: "126px",
                  fontSize: "13px",
                  minHeight: "45px",
                  fontFamily: themeFonts["Poppins-Bold"],
                  padding: "11px",
                  textTransform: "capitalize",
                  "&.Mui-selected": {
                    background: "#00ADB5",
                    color: "#FFFFFF",
                  },
                }}
              />
              <Tab
                label="For Curators"
                value="curators"
                sx={{
                  color: "#00ADB5",
                  borderRadius: "5px",
                  width: "126px",
                  fontSize: "13px",
                  padding: "11px",
                  minHeight: "45px",
                  fontFamily: themeFonts["Poppins-Bold"],
                  textTransform: "capitalize",
                  "&.Mui-selected": {
                    background: "#00ADB5",
                    color: "#FFFFFF",
                  },
                }}
              />
            </TabList>
          </Box>

          <TabPanel
            value="artists"
            sx={{
              padding: "0 !important",
            }}
          >
            <>
              <Box className="display-none-sm">
                <Button
                  startIcon={<MusicIcon />}
                  sx={{
                    fontSize: "16px",
                    fontFamily: themeFonts["Poppins-Bold"],
                    color: themeColors["#00ADB5"],
                    padding: "10px",
                    marginTop: "32px",
                    height: "30px",
                  }}
                >
                  Submissions
                </Button>
                <Box
                  sx={{
                    maxWidth: 253,
                    background: themeColors["#F0F0F0"],
                    padding: "12px",
                    marginTop: "32px",
                    display: isMiniScreen ? "none" : "block",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: themeFonts["Poppins-Bold"],
                      color: themeColors["#000000"],
                      marginBottom: "8px",
                    }}
                  >
                    Want to switch roles?
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontFamily: themeFonts["Poppins-SemiBold"],
                      color: themeColors["#00000080"],
                      textTransform: "capitalize",
                      letterSpacing: " 0.39px",
                    }}
                  >
                    Just click on either "For Artists" or "For Curators," and
                    the website will update your view accordingly.
                  </Typography>
                </Box>
              </Box>
            </>
          </TabPanel>
          <TabPanel
            value="curators"
            sx={{
              padding: "0 !important",
            }}
          >
            <>
              <Box
                // className="display-none-sm"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <TabContext value={curatorTab}>
                  <TabList
                    onChange={handleCuratorTabChange}
                    aria-label="lab API tabs example"
                    sx={{
                      width: "100%",
                      minHeight: "45px",
                      marginTop: "20px",
                      "& span.MuiTabs-indicator": {
                        display: "none",
                      },
                      "& .MuiTabs-flexContainer": {
                        flexDirection: "column",
                        minHeight: "45px",
                        gap: "12px",
                      },
                    }}
                  >
                    <Tab
                      label="Submitted to you"
                      value="submitted"
                      icon={<PlayButtonIcon2 />}
                      iconPosition="start"
                      sx={{
                        fontSize: "16px",
                        fontFamily: themeFonts["Poppins-Regular"],
                        color: themeColors["#00000080"],
                        padding: "10px",
                        minHeight: "30px",
                        justifyContent: "flex-start",
                        textTransform: "capitalize",
                        "& svg path": { stroke: themeColors["#00000080"] },
                        "&.Mui-selected": {
                          color: themeColors["#00ADB5"],
                          fontFamily: themeFonts["Poppins-SemiBold"],
                          fontSize: "16px",
                          "& svg path": { stroke: themeColors["#00ADB5"] },
                        },
                      }}
                    />
                    <Tab
                      label=" Your Playlists"
                      value="playlists"
                      icon={<MusicIcon2 />}
                      iconPosition="start"
                      sx={{
                        fontSize: "16px",
                        fontFamily: themeFonts["Poppins-Regular"],
                        color: themeColors["#00000080"],
                        padding: "10px",
                        minHeight: "30px",
                        justifyContent: "flex-start",
                        textTransform: "capitalize",
                        "& svg path": { fill: themeColors["#00000080"] },
                        "&.Mui-selected": {
                          color: themeColors["#00ADB5"],
                          fontFamily: themeFonts["Poppins-SemiBold"],
                          fontSize: "16px",
                          "& svg path": {
                            fill: themeColors["#00ADB5"],
                            "& .MuiSvgIcon-root": {
                              fontSize: "40px !important",
                            },
                          },
                        },
                      }}
                    />
                  </TabList>
                </TabContext>
                <Box
                  sx={{
                    maxWidth: 253,
                    background: themeColors["#F0F0F0"],
                    padding: "12px",
                    marginTop: "15px",
                    display: isMiniScreen ? "none" : "block",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: themeFonts["Poppins-SemiBold"],
                      color: themeColors["#000000"],
                      marginBottom: "8px",
                    }}
                  >
                    Want to switch roles?
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontFamily: themeFonts["Poppins-Bold"],
                      color: themeColors["#00000080"],
                      textTransform: "capitalize",
                      letterSpacing: " 0.39px",
                    }}
                  >
                    Just click on either "For Artists" or "For Curators," and
                    the website will update your view accordingly.
                  </Typography>
                </Box>
                {data && data.userPlaylist.length !== 0 && (
                  <Box
                    sx={{
                      maxWidth: 253,
                      background: themeColors["#FFCC33"],
                      padding: "12px",
                      marginTop: "32px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontFamily: themeFonts["Poppins-SemiBold"],
                        color: themeColors["#000000"],
                        marginBottom: "8px",
                      }}
                    >
                      Share your referral link
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontFamily: themeFonts["Poppins-SemiBold"],
                        color: themeColors["#00000080"],
                        textTransform: "capitalize",
                        letterSpacing: " 0.39px",
                        display: "flex",
                        alignItems: "end",
                      }}
                    >
                      Invite other Spotify curators to earn extra points in our
                      weekly playlist promotion competition{" "}
                      <HtmlTooltip
                        title={
                          <Typography
                            sx={{
                              margin: "0px",
                              fontFamily: themeFonts["Poppins-Medium"],
                              fontSize: "13px",
                              color: "#000000",
                              lineHeight: "19.5px",
                              textTransform: "capitalize",
                            }}
                          >
                            When your invited curator adds at least one playlist
                            to our website, and they provide feedback on first
                            song from their dashboard, you'll earn bonus points,
                            boosting your chances for free playlist promotion!
                          </Typography>
                        }
                        placement="top"
                        arrow
                      >
                        <Button
                          style={{
                            padding: "0px",
                            margin: "0px",
                            minWidth: "5px",
                            minHeight: "5px",
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          <InfoIcon />
                        </Button>
                      </HtmlTooltip>
                    </Typography>
                    <TextField
                      sx={{
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          background: themeColors["#FFFFFF"],
                          height: "39px",
                          borderRadius: 0,
                          fontFamily: themeFonts["Poppins-Regular"],
                          fontSize: "10px",
                          color: themeColors["#00000080"],
                          paddingX: "6px",
                        },
                        "& input::placeholder": {
                          opacity: 1,
                        },
                        width: "100%",
                        marginTop: "12px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              marginRight: 0,
                            }}
                          >
                            <IconButton>
                              <LinkIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              marginRight: 0,
                            }}
                          >
                            <IconButton onClick={copyToClipboard}>
                              <CopyIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        onClick: copyToClipboard,
                      }}
                      type="text"
                      inputRef={inputRef}
                      // defaultValue={`https://pitch-playlist.vercel.app/ref/${id}`}
                      // defaultValue={`http://localhost:3000/ref/${id}`}
                     defaultValue={`https://pitchplaylists.com/ref/${id}`}
                    />
                  </Box>
                )}
              </Box>
            </>
          </TabPanel>
        </TabContext>
      </Box>
    </Grid>
  );
};
