import {
  Box,
  Dialog,
  Link as MuiLink,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";
import { themeColors, themeFonts } from "../../configs";
import { ROUTES } from "../consts/routes.consts";
import { useNavigate } from "react-router-dom";

export const ProfileMenu = ({
  open,
  handleClose,
  anchorEl,
  auth,
}: {
  open: any;
  handleClose: any;
  anchorEl: any;
  auth: any;
}) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    window.localStorage.removeItem("id");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("userName");
    window.localStorage.removeItem("userEmail");
    window.localStorage.removeItem("userCredits");
    window.localStorage.removeItem("refresh_token");
    navigate(ROUTES.HOME);
    handleClose();
  };
  const userName = localStorage.getItem("userName");
  const userEmail = localStorage.getItem("userEmail");
  return (
    <>
      <Box>
        <Box sx={{ float: "right" }}>
          <Dialog onClose={handleClose} open={open}>
            <Box>
              <Menu
                id="isLive"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "menu-button",
                }}
                sx={{
                  "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper":
                    {
                      top: "65px !important",
                      borderRadius: "6px !important",
                    },
                }}
              >
                <MenuItem onClick={handleClose}>
                  <MuiLink
                    sx={{
                      color: themeColors["#000000"],
                      fontSize: "12px",
                      fontFamily: themeFonts["Poppins-Medium"],
                      textDecoration: "none",
                    }}
                  >
                    {userName}
                    <Typography
                      sx={{
                        color: themeColors["#00000066"],
                        fontSize: "10px",
                        fontFamily: themeFonts["Poppins-Regular"],
                      }}
                    >
                      {userEmail}
                    </Typography>
                  </MuiLink>
                </MenuItem>
                <Divider sx={{ width: "100%", margin: "0px !important" }} />

                <MenuItem
                  onClick={() => {
                    navigate(ROUTES.ACCOUNT);
                    handleClose();
                  }}
                >
                  <MuiLink
                    sx={{
                      color: themeColors["#000000"],
                      fontSize: "12px",
                      fontFamily: themeFonts["Poppins-Medium"],
                      textDecoration: "none",
                    }}
                  >
                    Account
                  </MuiLink>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <MuiLink
                    sx={{
                      color: themeColors["#000000"],
                      fontSize: "12px",
                      fontFamily: themeFonts["Poppins-Medium"],
                      textDecoration: "none",
                    }}
                  >
                    Logout
                  </MuiLink>
                </MenuItem>
              </Menu>
            </Box>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};
