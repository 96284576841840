import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { themeFonts } from "../../../configs";

function createData(
  name: string,
  purpose: string,
  dataCollection: string,
  dataSharing: string
) {
  return { name, purpose, dataCollection, dataSharing };
}

const rows = [
  createData(
    "Google Analytics",
    "Analytics",
    "Collects data for website traffic analysis and personalized ads",
    "Limited, respecting privacy (e.g., IP anonymization)"
  ),
  createData(
    "MetaAds",
    "Advertising platform for promotions",
    "Limited to user engagement data for ad targeting",
    "No public sharing; used for promotion"
  ),
  createData(
    "Referral Code Plugin",
    "Facilitates sharing of referral codes",
    "Limited to referral code information for tracking",
    "No public sharing; used for referral program"
  ),
];

export default function PrivacySectionTableTwo() {
  return (
    <TableContainer
      component={Paper}
      sx={{
        "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1":
          {
            boxShadow: "0",
            borderRadius: "0px",
          },
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: "#EFEFEF", color: "#000000 !important", }}>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                 fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Plugin/Service Name
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Purpose
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Data Collection
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Data Sharing
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{}}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.purpose}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.dataCollection}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.dataSharing}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
