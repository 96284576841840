import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createTransaction: builder.mutation<any, TransactionApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/transaction`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
    createTransactionOrder: builder.mutation<any, TransactionApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/transaction/orders`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
    createTransactionOrderCapture: builder.mutation<any, TransactionApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/transaction/order/capture`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
  }),
});
export { injectedRtkApi as enhancedApi };

export type TransactionApiArg = {
  userId?: string;
  credits?: any;
  amount?: any;
  token?: any;
  paymentMethod?: any;
  customerId?: any;
  orderID?: any;
};

export const {
  useCreateTransactionMutation,
  useCreateTransactionOrderMutation,
  useCreateTransactionOrderCaptureMutation,
} = injectedRtkApi;
