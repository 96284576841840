import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlayList: builder.query<[], void>({
      query: () => {
        return {
          url: `/api/playlist/submitted`,
        };
      },
    }),
    getPlayListDetail: builder.query<any, Partial<PlaylistApiArg>>({
      query: (queryArg) => {
        return {
          url: `/api/playlist/playlist-detail`,
          params: queryArg,
        };
      },
    }),
    getPlayListById: builder.query<any, Partial<PlaylistApiArg>>({
      query: (queryArg) => {
        return {
          url: `/api/playlist`,
          params: queryArg,
        };
      },
    }),
    createPlaylist: builder.mutation<any, PlaylistApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/playlist`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
    updatePlayList: builder.mutation<any, Partial<PlaylistApiArg>>({
      query: (queryArg) => {
        return {
          url: `/api/playlist/status`,
          method: "PUT",
          body: queryArg,
        };
      },
    }),
    deletePlayList: builder.mutation<any, Partial<PlaylistApiArg>>({
      query: (queryArg) => {
        return {
          url: `/api/playlist/update`,
          method: "PUT",
          body: queryArg,
        };
      },
    }),
  }),
});
export { injectedRtkApi as enhancedApi };

export type PlaylistApiArg = {
  playlistId?: string;
  genres?: any;
  userId?: any;
  status?: any;
  trackId?: any;
  uri?: any;
  declineReason?: any;
  id?: any;
  isActive?: any;
  submitConditions?: {
    followUserUrls?: any;
    followPlaylistUrls?: any;
    platformType?: String;
    saveTrackUrl?: String;
  };
};

export const {
  useCreatePlaylistMutation,
  useGetPlayListQuery,
  useGetPlayListDetailQuery,
  useGetPlayListByIdQuery,
  useUpdatePlayListMutation,
  useDeletePlayListMutation,
} = injectedRtkApi;
