import { Box, Button, Dialog, Typography } from "@mui/material";
import { themeColors, themeFonts } from "../../configs";
import { CustomFilledSelect } from "../select";
import { CustomAccordion } from "../accordion/custom-accordion";
import { useUpdateGatesMutation } from "../apis/gatesApi";
import { useEffect } from "react";
import { toast } from "react-toastify";
export const EditGatesDialog = (props: any) => {
  const {
    handleCloseData,
    isOpen,
    editedData,
    refetch,
    playlistId,
    setPlatformType,
    setSpotifyAccountLink,
    setSpotifyPlaylistLink,
    setSpotifySongLink,
    platformType,
    spotifyAccountLink,
    spotifyPlaylistLink,
    spotifySongLink,
  } = props;
  const editGatesDialog = "Edit Gates Dialog";
  const track = "https://open.spotify.com/track";
  const playlist = "https://open.spotify.com/playlist";
  const userPattern = "https://open.spotify.com/user";
  const artistPattern = "https://open.spotify.com/artist";
  const [updateGenres] = useUpdateGatesMutation();

  const isInvalidTrackUrl =
    spotifySongLink !== "" && !spotifySongLink.includes(track);
  const isInvalidPlaylistUrl =
    spotifyPlaylistLink !== "" && !spotifyPlaylistLink.includes(playlist);
  const isInvalidAccountUrl =
    spotifyAccountLink !== "" &&
    !(
      spotifyAccountLink.includes(userPattern) ||
      spotifyAccountLink.includes(artistPattern)
    );
  useEffect(() => {
    if (editedData) {
      setPlatformType(editedData?.platformType || "Spotify");
      setSpotifyAccountLink(
        editedData.followUserUrls ? editedData.followUserUrls : ""
      );
      setSpotifyPlaylistLink(
        editedData.followPlaylistUrls ? editedData.followPlaylistUrls : ""
      );
      setSpotifySongLink(editedData.saveTrackUrl || "");
    }
  }, [
    editedData,
    setPlatformType,
    setSpotifyAccountLink,
    setSpotifyPlaylistLink,
    setSpotifySongLink,
  ]);
  useEffect(() => {
    if (isInvalidTrackUrl) {
      setTimeout(() => {
        setSpotifySongLink("");
      }, 1000);
    }
  }, [isInvalidTrackUrl, setSpotifySongLink]);
  useEffect(() => {
    if (isInvalidPlaylistUrl) {
      setTimeout(() => {
        setSpotifyPlaylistLink("");
      }, 1000);
    }
  }, [isInvalidPlaylistUrl, setSpotifyPlaylistLink]);
  useEffect(() => {
    if (isInvalidAccountUrl) {
      setTimeout(() => {
        setSpotifyAccountLink("");
      }, 1000);
    }
  }, [isInvalidAccountUrl, setSpotifyAccountLink]);
  const handleSubmit = async () => {
    if (editedData) {
      try {
        await updateGenres({
          playlistId: playlistId,
          submitConditions: {
            followUserUrls: spotifyAccountLink,
            followPlaylistUrls: spotifyPlaylistLink,
            platformType: platformType,
            saveTrackUrl: spotifySongLink,
          },
        }).unwrap();
        toast.success("Success");
      } catch (error: any) {
        toast.error("Failed");
      }
      refetch();
    }
    handleCloseData();
  };

  return (
    <Box>
      <Box sx={{ float: "right" }}>
        <Dialog
          onClose={handleCloseData}
          open={isOpen}
          sx={{
            "& .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper": {
              maxWidth: "446px",
              width: "100%",
              borderRadius: "12px",
              // position: "relative",
              padding: "16px",

              backgroundColor: "#FFFFFF",
              boxShadow: "10px 10px 10px 0px #0000001A",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                color: themeColors["#000000"],
              }}
            >
              Edit Gate Info
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontFamily: themeFonts["Poppins-Regular"],
                color: themeColors["#00000080"],
                maxWidth: "264px",
                textAlign: "center",
                marginBottom: "16px",
              }}
            >
              Specify the automatic actions required for users to submit a song
              to this playlist
            </Typography>
          </Box>
          <Box>
            <CustomFilledSelect
              options={[
                {
                  label: "Spotify",
                  value: "Spotify",
                },
              ]}
              value={platformType}
              label="Platform Type"
              editGatesDialog={editGatesDialog}
              height="29px"
              onChange={(e) => setPlatformType(e)}
            />
          </Box>
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <CustomAccordion
              title={"Follow Account on Spotify"}
              description={"Get New Spotify Followers On Your Account"}
              label={"Spotify Account Link (User/Artist)"}
              value={spotifyAccountLink}
              onChange={(e: any) => setSpotifyAccountLink(e.target.value)}
              isInvalid={isInvalidAccountUrl}
            />
            <CustomAccordion
              title={"Follow Playlist on Spotify"}
              description={"Get New Spotify Playlist Followers"}
              label={"Spotify Playlist Link"}
              value={spotifyPlaylistLink}
              onChange={(e: any) => setSpotifyPlaylistLink(e.target.value)}
              isInvalid={isInvalidPlaylistUrl}
            />
            <CustomAccordion
              title={"Save Song on Spotify"}
              description={"Get new Spotify save"}
              label={"Spotify Song Link"}
              value={spotifySongLink}
              onChange={(e: any) => setSpotifySongLink(e.target.value)}
              isInvalid={isInvalidTrackUrl}
            />
          </Box>

          <Box
            sx={{
              textAlign: "center",
              marginTop: "32px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                padding: "7px 47px",
                backgroundColor: themeColors["#00ADB5"],
                color: themeColors["#FFFFFF"],
                borderRadius: "5px",
                height: "auto",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Save Actions
            </Button>
            <Button
              onClick={handleCloseData}
              variant="text"
              sx={{
                padding: "7px 65.5px",
                color: themeColors["#00000080"],
                borderRadius: "5px",
                height: "auto",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Cancel
            </Button>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};
