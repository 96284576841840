import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDayCount: builder.query<any, void>({
      query: () => {
        return {
          url: `/api/day-count`,
        };
      },
    }),
  }),
});


export { injectedRtkApi as enhancedApi };



export const {useGetDayCountQuery } = injectedRtkApi;