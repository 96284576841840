import { List } from "@mui/material";
import { IMenuList } from "../../configs";
import { ListItemButton, ListItemText } from "../navbar";
interface ListItemProps {
  menu: Array<IMenuList>;
  navigate: Function;
  pathname: string;
  isDashboardPage: any;isSubmitPage?:any
}
export const ListItems = ({
  menu,
  navigate,
  pathname,
  isDashboardPage,isSubmitPage
}: ListItemProps) => {
  return (
    <List 
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {menu.map((item, index) => (
        <ListItemButton 
          key={`menu-${index}`}
          selected={pathname === item.route}
          onClick={() => navigate(item.route)}
          isDashboardPage={isDashboardPage}isSubmitPage={isSubmitPage}
        >
          <ListItemText primary={item.title} className="underline" isSubmitPage={isSubmitPage}/>
        </ListItemButton>
      ))}
    </List>
  );
};
