import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { FocusEventHandler, ReactNode, useState } from "react";
import { themeColors, themeFonts } from "../../configs";
import { ArrowDownIcon, EditGatesArrowIcon } from "../../svgs";

export type CustomSelectOption = {
  label: string;
  value: string | number;
};

interface CustomSelectProps {
  label?: string;
  options: Array<CustomSelectOption>;
  helperText?: string;
  value?: number | string | null;
  errorStatus?: string;
  filled?: boolean;
  id?: string;
  disabled?: boolean;
  height?: string;
  onChange?: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  fontFamily?: string;
  fontSize?: string;
  titleComponent?: ReactNode;
  color?: string;
  editGatesDialog?: any;
}

export const CustomFilledSelect = ({
  label,
  options,
  helperText,
  value,
  errorStatus,
  filled = true,
  id,
  disabled = false,
  onBlur,
  onChange,
  height,
  fontFamily,
  fontSize,
  color,
  titleComponent,
  editGatesDialog,
}: CustomSelectProps) => {
  const [selectValue, setSelectValue] = useState(value ?? "");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value as string);
    if (onChange) onChange(event.target.value as string);
  };

  const borderStyle = () => {
    if (errorStatus) return "2px solid #e11900";
    if (filled) return "none";
    return "1px solid #0000003b";
  };

  return (
    <FormControl fullWidth>
      {label && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: fontFamily
                ? { fontFamily }
                : themeFonts["Poppins-Regular"],
              color: color ? { color } : themeColors["#00000080"],
              fontSize: fontSize ? { fontSize } : editGatesDialog ? 10 : 14,
            }}
          >
            {label}
          </Typography>
          {titleComponent}
        </Box>
      )}

      <Select
        value={selectValue.toString()}
        onChange={handleChange}
        onBlur={onBlur}
        id={id}
        disabled={disabled}
        IconComponent={() =>
          editGatesDialog ? <EditGatesArrowIcon /> : <ArrowDownIcon />
        }
        sx={{
          height: height ? { height } : 50,
          borderRadius: editGatesDialog ? "5px" : 2,
          background: editGatesDialog
            ? "#00ADB5"
            : filled
            ? "#F0F0F0"
            : "transparent",
          "& .MuiOutlinedInput-notchedOutline": {
            border: borderStyle(),
          },

          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: borderStyle(),
          },
          "& .MuiSelect-selected .MuiOutlinedInput-notchedOutline": {
            border: borderStyle(),
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-outlined.MuiSelect-select":
            {
              position: "relative",
              overflow: "overlay",
              zIndex: 1,
            },
          "& svg": {
            position: "absolute",
            right: "13px",
          },
          fontFamily: editGatesDialog
            ? themeFonts["Poppins-SemiBold"]
            : themeFonts["Poppins-Medium"],
          fontSize: editGatesDialog ? 12 : 14,
          color: editGatesDialog
            ? themeColors["#FFFFFF"]
            : themeColors["#000000"],
          marginTop: 1,
        }}
      >
        {options.map((item, index) => (
          <MenuItem key={`custom-select-option-${index}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {!selectValue && selectValue !== 0 && (
        <FormHelperText
          sx={{
            fontSize: 12,
            fontFamily: themeFonts["Poppins-Regular"],
            color: themeColors["#323B4B"],
            marginLeft: 0,
            position: "absolute",
            top: "40px",
            left: "14px",
            opacity: 0.5,
          }}
        >
          {helperText}
        </FormHelperText>
      )}
      {/* <ErrorStatusMessage error={errorStatus} /> */}
    </FormControl>
  );
};
