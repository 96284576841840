import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Link as MuiLink,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { themeColors, themeFonts } from "../../configs";
import { ChipImg2, LogBackground2, SpotifyGreenLogo } from "../../pngs";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../components/consts/routes.consts";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Navigation } from "swiper/modules";
import { useGetPlayListQuery } from "../../components/apis/playlist";
import { useEffect, useState } from "react";

interface Playlist {
  _id: string;
  playlistName: string;
  totalFollowers: string;
  imageUrl: string;
  // Add other properties here based on your data structure
}

export const PopularPlayList = () => {
  const { data: playlist } = useGetPlayListQuery<any>();
  const [sortedPlaylists, setSortedPlaylists] = useState<Playlist[]>([]);

  useEffect(() => {
    if (playlist) {
      const sorted = [...playlist].sort((a, b) => {
        return parseInt(b.totalFollowers, 10) - parseInt(a.totalFollowers, 10);
      });
      setSortedPlaylists(sorted);
    }
  }, [playlist]);

  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const navigate = useNavigate();
  const truncateString = (str: any, num: any) => {
    if (str?.length <= num) {
      return str;
    }
    return str?.slice(0, num) + "...";
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          paddingLeft: "0 !important",
          paddingY: isSmallScreen ? "40px" : "80px",
          background: themeColors["#EEEEEE99"],
        }}
      >
        <Chip
          label="Popular Playlists"
          sx={{
            color: "#FFFFFF",
            fontSize: "18px",
            fontFamily: themeFonts["Poppins-Medium"],
            background: `url(${ChipImg2})`,
            height: "35px",
            paddingX: "8px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
        <Typography
          sx={{
            color: "#000000",
            fontSize: "32px",
            fontFamily: themeFonts["Poppins-SemiBold"],
            marginTop: "16px",
            maxWidth: 652,
            textAlign: "center",
          }}
        >
          Submit your song to explore playlists covering over 80 different
          genres
        </Typography>
        {isSmallScreen && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              gap: "32px",
              maxWidth: isSmallScreen ? "100%" : "1300px",
              justifyContent: "center",
              marginY: "20px",
            }}
          >
            <Swiper
              className="navigate-icon"
              style={{ height: "270px", maxWidth: "600px" }}
              slidesPerView={1}
              spaceBetween={30}
              freeMode={true}
              cssMode={true}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination, Navigation]}
            >
              {sortedPlaylists?.slice(0, 6).map((val: any, index: any) => (
                <SwiperSlide>
                  <Box
                    sx={{
                      width: "80%",
                      backgroundColor: "#ffffff",
                      boxShadow: "0px 0px 5px #000000",
                      padding: "14px",
                      paddingTop: "0px",
                      margin: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gridGap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "110px",
                          height: "120.5px",
                          paddingTop: "10.5px",
                        }}
                      >
                        <img
                          src={val.imageUrl}
                          alt=""
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Box>
                      <Box sx={{ width: "60%" }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Box
                            sx={{
                              eight: "42px",
                              width: "91px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={SpotifyGreenLogo}
                              alt=""
                              style={{ width: "70px", height: "21px" }}
                            />
                          </Box>
                        </Box>
                        <MuiLink
                          component={Link}
                          to={val?.playlistUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: "#000000",
                            fontSize: "16px",
                            fontFamily: themeFonts["Poppins-SemiBold"],
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textDecoration: "none",
                            wordWrap: "break-word",
                            maxWidth: "150px",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {truncateString(val?.playlistName, 25)}
                        </MuiLink>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "3px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#00ADB5",
                              textTransform: "capitalize",
                              display: "-webkit-box",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textDecoration: "none",
                            }}
                          >
                            {" "}
                            By
                          </Typography>
                          <MuiLink
                            component={Link}
                            to={val?.userId?.profileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#00ADB5",
                              textTransform: "capitalize",
                              display: "-webkit-box",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textDecoration: "none",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {val.ownerName}
                          </MuiLink>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "16px",
                        display: "flex",
                        borderTop: "1px solid #95959566",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#8e8e8e",
                          fontSize: "14px",
                          fontFamily: themeFonts["Poppins-Regular"],
                          marginTop: "10px",
                          paddingRight: "8px",
                        }}
                      >
                        Followers : {val.totalFollowers}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          border: "1px solid #95959566",
                          marginTop: "10px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#8e8e8e",
                          fontSize: "14px",
                          fontFamily: themeFonts["Poppins-Regular"],
                          marginTop: "10px",
                          paddingLeft: "8px",
                        }}
                      >
                        # of Tracks : {val.totalTracks}
                      </Typography>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        )}
        {!isSmallScreen && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              gap: "20px",
              maxWidth: isSmallScreen ? "100%" : "1090px",
              justifyContent: "center",
              marginY: "40px",
            }}
          >
            {sortedPlaylists?.slice(0, 6).map((val: any, index: any) => (
              <Box
                sx={{
                  width: isMiniScreen ? "auto" : "350px",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 5px #000000",
                  padding: "14px",
                  paddingTop: "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gridGap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      width: "110px",
                      height: "120.5px",
                      paddingTop: "10.5px",
                    }}
                  >
                    <img
                      src={val.imageUrl}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                  <Box sx={{ width: "60%" }}>
                    <Box
                      sx={{
                        height: "42px",
                        width: "91px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={SpotifyGreenLogo}
                        alt=""
                        style={{ width: "70px", height: "21px" }}
                      />
                    </Box>
                    <Box sx={{ paddingLeft: "10.5px" }}>
                      <MuiLink
                        component={Link}
                        to={val?.playlistUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "#000000",
                          fontSize: "16px",
                          fontFamily: themeFonts["Poppins-SemiBold"],
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textDecoration: "none",
                          wordWrap: "break-word",
                          maxWidth: "150px",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {truncateString(val?.playlistName, 25)}
                      </MuiLink>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#00ADB5",
                            textTransform: "capitalize",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textDecoration: "none",
                          }}
                        >
                          {" "}
                          By
                        </Typography>
                        <MuiLink
                          component={Link}
                          to={val?.userId?.profileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#00ADB5",
                            textTransform: "capitalize",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {val.ownerName}
                        </MuiLink>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "16px",
                    display: "flex",
                    borderTop: "1px solid #95959566",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#8e8e8e",
                      fontSize: "14px",
                      fontFamily: themeFonts["Poppins-Regular"],
                      marginTop: "10px",
                      paddingRight: "8px",
                    }}
                  >
                    Followers : {val.totalFollowers}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ border: "1px solid #95959566", marginTop: "10px" }}
                  />
                  <Typography
                    sx={{
                      color: "#8e8e8e",
                      fontSize: "14px",
                      fontFamily: themeFonts["Poppins-Regular"],
                      marginTop: "10px",
                      paddingLeft: "8px",
                    }}
                  >
                    # of Tracks : {val.totalTracks}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
        <Button
          variant="contained"
          onClick={() => {
            navigate(ROUTES.SUBMIT_A_SONG);
          }}
          sx={{
            height: "45px",
            background: themeColors["#00ADB5"],
            color: "#FFFFFF",
            fontSize: "16px",
            fontFamily: themeFonts["Poppins-Medium"],
            textAlign: "center",
            ":hover": {
              background: "#00ADB5",
            },
          }}
        >
          Submit your song
        </Button>
      </Grid>
      <Box
        sx={{
          background: `url(${LogBackground2})`,
          paddingY: isMiniScreen ? "20px" : "48px",
          paddingX: isMiniScreen ? "20px" : 0,
          display: "flex",
          alignItems: "center",
          justifyContent: isMiniScreen ? "center" : "space-evenly",
          backgroundSize: "cover",
          flexWrap: "wrap",
          gap: isMiniScreen ? "10px" : 0,
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: isSmallScreen ? "22px" : "32px",
            fontFamily: themeFonts["Poppins-SemiBold"],
          }}
        >
          Expand your Playlist Audience
        </Typography>
        <MuiLink
          component={Link}
          to={ROUTES.SUBMIT_A_PLAYLIST}
          sx={{
            textDecoration: "none",
            border: "1px solid white",
            height: "45px",
            color: "#FFFFFF",
            fontSize: "16px",
            fontFamily: themeFonts["Poppins-Medium"],
            textAlign: "center",
            padding: "11px 23px",
          }}
        >
          Submit a playlist
        </MuiLink>
      </Box>
    </>
  );
};
