import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const AcceptablePolicy = ({
  isMediumScreen,
}: {
  isMediumScreen: any;
}) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "32px",
          border: "0.5px solid #00000040",
          borderRadius: "16px 16px 0px 0px",
        }}
      >
        <Box
          sx={{
            paddingRight: "24px",
          }}
        >
          <Typography className="terms-about-text">
            Playersaloons, Website hereinafter referred to as ("We, Our, Us,
            Service Provider, Pitchplaylists"), which operates the website
            located at{" "}
            <Link
              // to={"https://pitch-playlist.vercel.app/"}
              to={"https://pitchplaylists.com/"}
              target="_blank"
              className="terms-link-text"
            >
              https://pitchplaylists.com/
            </Link>{" "}
            and other websites (collectively, the “Website”) and related online
            services, including any other products and services that Company may
            provide now or in the future (collectively, the “Services”
            “Products”). The following Terms of Acceptable Use Policy act as a
            binding legal contract between you (“you” and “your”) and the
            Service Provider regarding your use of the Services. All services
            rendered by Our company are for lawful purposes only. Users are
            prohibited from transmitting, storing, or presenting any unlawful
            content or materials, including but not limited to unauthorized
            copyrighted materials, government or military data, adult content,
            or illegal activities. We do not support or endorse any services
            that violate the rights of others, engage in illegal activities, or
            promote objectionable content. Use of the Website and Services is
            governed by these Terms of Service, Acceptable Use Policy, and our
            Privacy Policy.
          </Typography>
          <Box>
            <Typography className="terms-sub-heading-2">
              1. Eligibility and Responsibilities
            </Typography>

            <Typography className="terms-about-text">
              To be eligible for and to use our services, including creating an
              Account or related Site Services, you must meet certain conditions
              and agree to the following terms:
            </Typography>

            <Typography className="terms-about-text">
              <ul>
                <li>
                  Be at least 13 years old (or of legal age in your
                  jurisdiction).
                </li>
                <li>Provide accurate information and update it as needed.</li>
                <li>
                  Acknowledge that we may share personal information when
                  necessary.
                </li>
                <li>Keep your account information confidential.</li>
                <li>Use our services lawfully and comply with our rules.</li>
                <li>Not harm our business, reputation, or goodwill.</li>
                <li>
                  Use your account for personal, not commercial, purposes.
                </li>
                <li>Have the legal authority to agree to these terms.</li>
                <li>Not infringe upon intellectual property rights.</li>
              </ul>
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading-2">
              2 User Conduct
            </Typography>
            <Typography className="terms-about-text">
              You are solely responsible for all content you upload ("upload")
              on or via the Service.We prohibit certain conduct and content,
              including:
            </Typography>

            <Typography className="terms-about-text">
              <ul>
                <li>Infringing intellectual property rights of others.</li>
                <li>
                  Uploading content you do not have the right to upload under
                  any law or contractual or fiduciary relationships.
                </li>
                <li>
                  Sending software viruses or any other code designed to
                  interrupt, destroy, or limit the functionality of computer
                  software or hardware.
                </li>
                <li>Creating privacy or security risks for others.</li>
                <li>
                  Sending unsolicited or unauthorized advertising, promotional
                  materials, commercial activities and/or sales, "junk mail,"
                  "spam," "chain letters," "pyramid schemes," "contests,"
                  "sweepstakes," or any other form of solicitation.
                </li>
                <li>
                  Uploading content that is unlawful, harmful, threatening,
                  abusive, harassing, tortious, excessively violent, defamatory,
                  vulgar, obscene, pornographic, libelous, invasive of another's
                  privacy, racially, ethnically, or otherwise objectionable.
                </li>
                <li>Engaging in any form of denial of service (DOS) attack.</li>
                <li>
                  Downloading or distributing content that infringes on
                  copyrights, trademarks, or service marks.
                </li>
              </ul>
            </Typography>

            <Typography className="terms-about-text">
              Violations may result in legal action, account suspension or
              termination, and removal of offending content from the Service. We
              reserve the right to investigate and take appropriate legal action
              against anyone who violates these rules.
            </Typography>

            <Typography className="terms-sub-heading-2">
              3. Commercial Use
            </Typography>
            <Typography className="terms-about-text">
              Unless otherwise expressly authorized herein or in the Service,
              you agree not to display, distribute, license, perform, publish,
              reproduce, duplicate, copy, create derivative works from, modify,
              sell, resell, exploit, transfer, or upload for any commercial
              purposes, any portion of the Service and website, use of the
              Service, or access to the Service.
            </Typography>

            <Typography className="terms-sub-heading-2">
              4. Prohibited Activities and Content
            </Typography>
            <Typography className="terms-about-text">
              We retain the authority to refuse access and service to any
              individual or entity. Our services are exclusively intended for
              lawful purposes, and any utilization in contravention of legal
              statutes or regulations is strictly prohibited.
            </Typography>
            <Typography className="terms-about-text">
              To provide further clarity, the subsequent list enumerates
              illustrative examples of activities and content that are strictly
              prohibited, We strictly prohibit engaging in the following
              activities associated with content: include but not limited to
              participating in fraudulent schemes, using unauthorized tools like
              bots or mail bombers, engaging in illegal sales or distribution,
              promoting illegal activities like gambling or piracy, and using
              our services for illegal purposes such as storing illegal
              substances or distributing malware.
            </Typography>
            <Typography className="terms-about-text">
              Furthermore, websites that promote illegal activities or contain
              links to other websites promoting illegal activities are strictly
              prohibited. This includes but not limited to human trafficking,
              prostitution, and child exploitation. We also ban adult materials,
              illegal content, warez, and games on our servers. Use of IRC is
              not allowed, and servers connected to IRC networks will be removed
              without notice.
            </Typography>

            <Typography className="terms-about-text">
              For inquiries or requests concerning these rights, please reach
              out to Us at:{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
