import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { themeFonts } from "../../../configs";

function createData(
  name: string,
  purpose: string,
  dataCollection: string,
  dataSharing: string,
  daysToRespond:string,
  
) {
  return { name, purpose, dataCollection, dataSharing, daysToRespond };
}

const rows = [
  createData(
    "Requests to Know",
    "0",
    "0",
    "0",
    "0",
  ),

  createData(
    "Requests to Delete",
    "0",
    "0",
    "0",
    "0",
  ),

  createData(
    "Requests to Opt-Out of Personal Information Sales",
    "0",
    "0",
    "0",
    "0",
  ),
];

export default function PrivacySectionTableFour() {
  return (
    <TableContainer
      component={Paper}
      sx={{
        "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1":
          {
            boxShadow: "0",
            borderRadius: "0px",
          },
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: "#EFEFEF", color: "#000000 !important", }}>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                 fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
          Request Type
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Received
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Granted (in whole or in part)
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
             Denied
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
             Days to Respond
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{}}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.purpose}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.dataCollection}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.dataSharing}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.daysToRespond}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
