import { Outlet } from "react-router-dom";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { DashboardMainLayout } from "./dashboard-main-layout";
import { DashboardSidebarLayout } from "./dashboard-sidebar-layout";
import { Footer } from "../footer";
import React, { useEffect, useState } from "react";
import { BackGroundImg } from "../../pngs";
import { themeFonts } from "../../configs";
import SelectMoreSubmissions from "../../components/modals/want-more-submissions";
export const DashboardLayout = () => {
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const isLargeScreen = useMediaQuery("(max-width:1382px)");
  const playlist = window.localStorage.getItem("playlist");
  const artistTab = playlist ? "curators" : "artists";
  const [value, setValue] = React.useState(artistTab);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const curatorsTab = playlist ? "playlists" : "submitted";
  useEffect(() => {
    setTimeout(() => {
      window.localStorage.removeItem("playlist");
    }, 5000);
  }, []);
  const [curatorTab, setCuratorTab] = React.useState(curatorsTab);
  const [hasOpen, setHasOpen] = useState(false);

  const handleOpenButton = () => {
    setHasOpen(true);
  };
  const handleCloseButton = () => {
    setHasOpen(false);
  };
  const handleCuratorTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setCuratorTab(newValue);
  };
  const getTimeUntilNextMonday = () => {
    const now: any = new Date();
    const nextMonday: any = new Date(now);
    nextMonday.setDate(now.getDate() + ((1 + 7 - now.getDay()) % 7 || 7));

    nextMonday.setHours(0, 0, 0, 0);
    return nextMonday - now;
  };

  const getTimeComponents = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = React.useState(
    getTimeUntilNextMonday()
  );

  const [timeComponents, setTimeComponents] = React.useState(
    getTimeComponents(timeRemaining)
  );

  const [displayFormat, setDisplayFormat] = useState("days");

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prevTimeRemaining) => {
        if (prevTimeRemaining <= 1000) {
          // Reset to 7 days after countdown finishes
          return 7 * 24 * 3600 * 1000;
        }
        return prevTimeRemaining - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const { days, hours, minutes, seconds } = getTimeComponents(timeRemaining);

    if (days > 0) {
      setDisplayFormat("days");
    } else if (hours > 0) {
      setDisplayFormat("hours");
    } else if (minutes > 0) {
      setDisplayFormat("minutes");
    } else {
      setDisplayFormat("seconds");
    }

    setTimeComponents({ days, hours, minutes, seconds });
  }, [timeRemaining]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        {value === "artists" && (
          <Box
            sx={{
              background: `url(${BackGroundImg})`,
              // paddingY: isMiniScreen ? "20px" : "18px",
              // paddingX: isMiniScreen ? "20px" : 0,
              display: "flex",
              justifyContent: isMiniScreen ? "center" : "flex-start",
              backgroundSize: "cover",
              flexWrap: "wrap",
              gap: isMiniScreen ? "10px" : 0,
              height: "71px",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: isMiniScreen
                  ? "12px"
                  : isSmallScreen
                  ? "14px"
                  : "18px",
                fontFamily: themeFonts["Poppins-Regular"],
                paddingLeft: isLargeScreen ? "12px" : "130px",
                paddingRight: isLargeScreen ? "12px" : "130px",
                textTransform: "capitalize",
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              Want to submit your track to more playlists? GET MORE CREDITS NOW!
              Curators are ready for your submission! {""}{" "}
              <Button
                sx={{
                  marginLeft: "auto",
                  padding: isLargeScreen ? "0px 0px" : "1px 11px",
                  backgroundColor: "#66FFBD",
                  color: "#000000",
                  borderRadius: "33px",
                  height: "auto",
                  cursor: "pointer",
                  fontSize: isLargeScreen ? "12px" : "18px",
                  textTransform: "uppercase",
                  fontFamily: themeFonts["Poppins-Medium"],
                  "&:hover": {
                    background: " #66FFBD",
                  },
                }}
                onClick={handleOpenButton}
              >
                Buy Now
              </Button>
            </Typography>
          </Box>
        )}
        {value === "curators" && (
          <Box
            sx={{
              background: `url(${BackGroundImg})`,
              // paddingY: isMiniScreen ? "20px" : "11px",
              // paddingX: isMiniScreen ? "20px" : 0,
              display: "flex",
              justifyContent: isMiniScreen ? "center" : "flex-start",
              backgroundSize: "cover",
              flexWrap: "wrap",
              gap: isMiniScreen ? "10px" : 0,
              height: "71px",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: isMiniScreen
                  ? "12px"
                  : isSmallScreen
                  ? "14px"
                  : "18px",
                fontFamily: themeFonts["Poppins-Regular"],
                paddingLeft: isLargeScreen ? "12px" : "130px",
                paddingRight: isLargeScreen ? "12px" : "130px",
                textTransform: "capitalize",
                display: "flex",
                columnGap: "12px",
                alignItems: "center",
              }}
            >
              Until the next curator announcement, who will receive free social
              media ad promotion for their Spotify playlist
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "3px",
                }}
              >
                {(["Days"] || ["Hours"] || ["Minutes"] || ["Seconds"]).map(
                  (label, index) => {
                    const Day =
                      [timeComponents.days][index] > 0 &&
                      String([timeComponents.days][index]).padStart(2, "0");
                    const Hours =
                      [timeComponents.hours][index] > 0 &&
                      String([timeComponents.hours][index]).padStart(2, "0");
                    const Minutes =
                      [timeComponents.minutes][index] > 0 &&
                      String([timeComponents.minutes][index]).padStart(2, "0");
                    const Seconds: any =
                      [timeComponents.seconds][index] > 0 &&
                      String([timeComponents.seconds][index]).padStart(2, "0");

                    return (
                      <Box
                        sx={{ display: "flex", columnGap: "2px" }}
                        key={label}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#66FFBD",
                            color: " #000000",
                            fontFamily: themeFonts["Poppins-Medium"],
                            borderRadius: "3px",
                            fontSize: "18px",
                            width: "27px",
                            height: "39px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          {Day
                            ? Day[0]
                            : Hours
                            ? Hours[0]
                            : Minutes
                            ? Minutes[0]
                            : Seconds[0]}
                          <Box
                            sx={{
                              backgroundColor: "#0000001A",
                              width: "100%",
                              height: `calc((100% / 7) * ${Day})`,
                              position: "absolute",
                              bottom: "0px",
                              borderRadius: "3px",
                            }}
                          ></Box>
                        </Box>

                        <Box
                          sx={{
                            backgroundColor: "#66FFBD",
                            color: " #000000",
                            fontFamily: themeFonts["Poppins-Medium"],
                            borderRadius: "3px",
                            fontSize: "18px",
                            width: "27px",
                            height: "39px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          {Day
                            ? Day[1]
                            : Hours
                            ? Hours[1]
                            : Minutes
                            ? Minutes[1]
                            : Seconds[1]}

                          <Box
                            sx={{
                              backgroundColor: "#0000001A",
                              width: "100%",
                              height: `calc((100% / 7) * ${Day})`,
                              position: "absolute",
                              bottom: "0px",
                              borderRadius: "3px",
                            }}
                          ></Box>
                        </Box>
                      </Box>
                    );
                  }
                )}
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: themeFonts["Poppins-Regular"],
                    fontSize: "8px",
                    textTransform: "capitalize",
                  }}
                >
                  {displayFormat}
                </Typography>
              </Box>
            </Typography>
          </Box>
        )}
      </Box>
      <DashboardMainLayout>
        <DashboardSidebarLayout
          curatorValue={value}
          handleChange={handleChange}
          curatorTab={curatorTab}
          handleCuratorTabChange={handleCuratorTabChange}
        />
        <Outlet context={{ value, curatorTab }} />
      </DashboardMainLayout>
      {hasOpen && (
        <SelectMoreSubmissions
          hasOpen={hasOpen}
          handleCloseButton={handleCloseButton}
        />
      )}
      <Footer />
    </Box>
  );
};
