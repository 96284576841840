import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { themeFonts } from "../../../configs";

function createData(
  name: string,
  purpose: string,
  dataCollection: string,

) {
  return { name, purpose, dataCollection, };
}

const rows = [
  createData(
    "A. Identifiers.",
    "None",
    "None",
  ),

  createData(
    "B: California Customer Records personal information categories.",
    "None",
    "None",
  ),

  createData(
    "C: Protected classification characteristics under California or federal law.",
    "None",
    "None",
  ),

  createData(
    "D: Commercial information.",
    "None",
    "None",
  ),

  createData(
    "E: Biometric information.",
    "None",
    "None",
  ),

  createData(
    "F: Internet or other similar network activity.",
    "None",
    "None",
  ),

  createData(
    "H: Sensory data.",
    "None",
    "None",
  ),

  createData(
    "I: Professional or employment-related information.",
    "None",
    "None",
  ),
  createData(
    "J: Non-public education information.",
    "None",
    "None",
  ),
  createData(
    "K: Inferences drawn from other personal information.",
    "None",
    "None",
  ),
 
];


export default function PrivacySectionTableFive() {
  return (
    <TableContainer
      component={Paper}
      sx={{
        "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1":
          {
            boxShadow: "0",
            borderRadius: "0px",
          },
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>


          <TableRow sx={{ background: "#EFEFEF", color: "#000000 !important", }}>
            <TableCell
              rowSpan={2}
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                 fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
            Personal Information Category
            </TableCell>


            <TableCell
            colSpan={2}
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
            Category of Third-Party Recipients
            </TableCell>

          </TableRow>

          <TableRow sx={{ background: "#EFEFEF", color: "#000000 !important", }}>
            <TableCell
             
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                 fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
            Business Purpose Disclosures
            </TableCell>

            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
                Sales
            </TableCell>

          </TableRow>

          
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{}}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.name}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.purpose}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.dataCollection}
              </TableCell>
          
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
