export const ROUTES = {
  HOME: "/",
  FAQ: "/faq",
  DASHBOARD: "/dashboard",
  TOP_CURATOR_CHART: "/top-curator-chart",
  CALLBACK: "/callback",
  SUBMIT_A_SONG: "/submit-song",
  SUBMIT_A_PLAYLIST: "/add-playlist",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  CONFIRM_PLAYLIST: "/confirm-playlist",
  SUBMIT_A_SONG_1: "/submit-song-1",
  SUBMIT_A_SONG_PLAYLIST: "/submit-song-playlist",
  VISIT_PUBLIC_PAGE: "/submit-public-page/:ids",
  REFERRAL_PAGE: "/ref/:id",
  PAGE_NOT_FOUND: "/page-not-found",
  ACCOUNT: "/account-setting",
};
