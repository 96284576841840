import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCuratorsList: builder.query<[], Partial<CuratorsListApiArg>>({
      query: (queryArg) => {
        return {
          url: `/api/curators`,
          params: queryArg,
        };
      },
    }),
  }),
});
export { injectedRtkApi as enhancedApi };
export type CuratorsListApiArg = { playlistId?: string };
export const { useGetCuratorsListQuery } = injectedRtkApi;
