import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

import { ROUTES } from "../../components/consts/routes.consts";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SpotifyIcon,} from "../../svgs";

const PageNotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { playlistId } = location.state || {};

  const handleGoBack = () => {
    if (playlistId) {
      navigate(`/submit-public-page/${playlistId}`);
    } else {
      navigate(ROUTES.SUBMIT_A_SONG);
    }
  };

  return (
    <Grid
      sx={{
        backgroundColor: "#000000",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            padding: "0 10px",
            "& svg path": {
              fill: "#11ab21 !important",
              height:"50px !important",
              width:"50px !important"
            },
          }}
        >
          
          <SpotifyIcon />
          

          <Typography
            sx={{
              color: "#ffffff",
              fontSize: "37px",
              fontWeight: "700",
              marginBottom: "10px",
            }}
          >
            Page not found
          </Typography>
          <Typography
            sx={{
              color: "#a7a7a7",
              fontFamily: "poppins-regular",
              fontSize: "14px",
              marginBottom: "20px",
            }}
          >
            We are unable to locate the page you provided due to a <br />{" "}
            Spotify error. Please check the track link or try again later.

          </Typography>
          <Button
            onClick={handleGoBack}
            variant="contained"
            sx={{
              fontSize: "14px",
              color: "#000000",
              backgroundColor: "#ffffff",
              borderRadius: "30px",
              padding: "8px 20px",
              height: "auto",
              marginBottom: "30px",
              display: "block",
              margin: "auto",
              "&:hover": {
                backgroundColor: "#ffffff",
                color: "#000000",
              },
            }}
          >
            Go back
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default PageNotFound;
