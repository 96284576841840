import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AtmCardIcon,
  CloseIconSvg,
  DollarIcon,
  DollarIcon2,
  StripeIcon,
} from "../../svgs";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { themeColors, themeFonts } from "../../configs";
import { CustomFilledInput } from "../input/custom-filled-input";
import { useGetPurchaseListQuery } from "../apis/purchaseAPi";
import { useCreateTransactionMutation } from "../apis/transactionApi";
import { toast } from "react-toastify";
import { useGetUsersListQuery } from "../apis/userDetailsAPi";
import { PayPalButton } from "../../pages/payment/paypal-button";
function SelectMoreSubmissions(props: any) {
  const { hasOpen, handleCloseButton } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isPaypal, setIsPaypal] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    if (isOpen === true) {
      setIsOpen(false);
    } else if (isPaypal === true) {
      setIsPaypal(false);
    } else {
      handleCloseButton();
    }
  };
  const [selectedOption, setSelectedOption] = useState("60Credits");

  const handleSelectOption = (option: any) => {
    setSelectedOption(option);
  };
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>();
  const handlePaymentMethod = (option: any) => {
    setSelectedPaymentOption(option);
  };
  useEffect(() => {
    if (selectedPaymentOption === "debit_cards") {
      setIsOpen(true);
    } else if (selectedPaymentOption === "paypal") {
      setIsPaypal(true);
    }
    if (isOpen === false) {
      setSelectedPaymentOption(null);
    }
  }, [isOpen, selectedPaymentOption]);

  const { data } = useGetPurchaseListQuery<any>();
  const minPackage = data?.pack.reduce((prev: any, current: any) =>
    prev.credits < current.credits ? prev : current
  );
  const maxPackage = data?.pack.reduce((prev: any, current: any) =>
    prev.credits > current.credits ? prev : current
  );
  const { refetch: creditsRefetch } = useGetUsersListQuery<any>();
  const credits =
    selectedOption === "60Credits" ? minPackage?.credits : maxPackage?.credits;
  const userId: any = localStorage.getItem("id");
  const total =
    selectedOption === "bestValue" ? maxPackage?.price : minPackage?.price;
  const [makePayment] = useCreateTransactionMutation();
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const cardNumberElement: any = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      throw new Error("Card element not found or is null");
    }
    if (cardNumberElement || cardExpiryElement || cardCvcElement) {
      const { error, token } = await stripe.createToken(cardNumberElement);

      if (error) {
        setError(error.message);
        setLoading(false);
        return;
      }

      try {
        await makePayment({
          userId: userId,
          credits: credits,
          amount: total,
          token: token.id,
          paymentMethod: "card",
        }).unwrap();
        toast.success("Success");
      } catch (error: any) {
        console.error("Error:", error.message);
        toast.error("Failed");
      }

      setLoading(false);
      handleCloseButton();
      creditsRefetch();
    }
  };

  return (
    <Box>
      <Box sx={{ float: "right" }}>
        <Dialog
          onClose={handleClose}
          open={hasOpen}
          sx={{
            "& .MuiPaper-root.MuiPaper-elevation": {
              maxWidth: "718px",
              width: "718px",
              borderRadius: "0px",
              // position: "absolute",
              padding: "50px 0px 27px 0px",
              boxShadow: "4px 4px 4px 0px #0000001A",
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 15,
            }}
          >
            <CloseIconSvg />
          </IconButton>
          <Box
            sx={{
              maxWidth: "718px",
              width: "100%",
              backgroundColor: "#FFFFFF",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#000000",
              }}
            >
              Purchase Credits
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#00000080",
              }}
            >
              Please note, these credits do not guarentee you a response from
              curators.
            </Typography>
            <Box
              sx={{
                marginTop: "32px",
                display: "flex",
                gap: "32px",
                justifyContent: "center",
                alignItems: "end",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  width: "175px",
                  backgroundColor:
                    selectedOption === "60Credits" ? "#00ADB5" : "#FFFFFF",
                  height: "fit-content",
                  border: "1px solid #00ADB5",
                  cursor: "pointer",
                  boxShadow: "6px 6px 6px 0px #0000001A",
                }}
                onClick={() => handleSelectOption("60Credits")}
              >
                <Typography
                  sx={{
                    marginTop: "15px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color:
                      selectedOption === "60Credits" ? "#FFFFFF" : "#000000",
                  }}
                >
                  {minPackage?.credits} credits
                </Typography>
                <Box
                  sx={{
                    marginTop: "7px",
                    marginBottom: "7px",
                  }}
                >
                  {selectedOption === "60Credits" ? (
                    <DollarIcon2 />
                  ) : (
                    <DollarIcon />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color:
                      selectedOption === "60Credits" ? "#FFFFFF" : "#000000",
                    marginBottom: "16px",
                  }}
                >
                  ${minPackage?.price}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "175px",
                  height: "auto",
                  border: "1px solid #00ADB5",
                  cursor: "pointer",
                  boxShadow: "6px 6px 6px 0px #0000001A",
                  backgroundColor:
                    selectedOption === "bestValue" ? "#00ADB5" : "#FFFFFF",
                }}
                onClick={() => handleSelectOption("bestValue")}
              >
                <Box
                  sx={{
                    backgroundColor:
                      selectedOption === "bestValue" ? "#FFFFFF" : "#00ADB5",
                    color:
                      selectedOption === "bestValue" ? "#00ADB5" : "#FFFFFF",
                    padding: "4px 0",
                    fontSize: "14px",
                    fontFamily: themeFonts["Poppins-Medium"],
                  }}
                >
                  Best Value
                </Box>
                <Typography
                  sx={{
                    marginTop: "15px",
                    fontSize: "14px",
                    fontFamily: themeFonts["Poppins-Medium"],
                    color:
                      selectedOption === "bestValue" ? "#FFFFFF" : "#000000",
                  }}
                >
                  {maxPackage?.credits} credits
                </Typography>
                <Box
                  sx={{
                    marginTop: "7px",
                    marginBottom: "7px",
                  }}
                >
                  {selectedOption === "bestValue" ? (
                    <DollarIcon2 />
                  ) : (
                    <DollarIcon />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: themeFonts["Poppins-Medium"],
                    color:
                      selectedOption === "bestValue" ? "#FFFFFF" : "#000000",
                    marginBottom: "16px",
                  }}
                >
                  ${maxPackage?.price}
                </Typography>
              </Box>
            </Box>

            {isOpen === true ? (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "32px",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "380px",
                    }}
                  >
                    <CustomFilledInput
                      type="text"
                      label="Name of cardholder"
                      placeholder="Name"
                      disableValue
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "380px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "12px",
                    }}
                  >
                    <Box sx={{ width: "250px" }}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#0000004D",
                          textAlign: "start",
                          fontFamily: themeFonts["Poppins-Medium"],
                        }}
                      >
                        Card Number
                      </Typography>
                      <CardNumberElement
                        // onChange={handleCardNumberChange}
                        options={{
                          style: {
                            base: {
                              iconColor: "black",
                              color: "black",
                              fontFamily: themeFonts["Poppins-Regular"],
                              fontSize: "14px",
                              fontSmoothing: "antialiased",
                              ":-webkit-autofill": {
                                color: "#fce883",
                              },
                              "::placeholder": {
                                color: "black",
                                fontFamily: themeFonts["Poppins-Regular"],
                              },
                            },
                            invalid: {
                              iconColor: "red",
                              color: "red",
                            },
                          },
                          placeholder: "---- ---- ---- ----",
                        }}
                      />
                    </Box>
                    <Box sx={{ width: "120px" }}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#0000004D",
                          textAlign: "start",
                          fontFamily: themeFonts["Poppins-Medium"],
                        }}
                      >
                        Expiry Date
                      </Typography>
                      <CardExpiryElement
                        // onChange={handleCardExpiryChange}
                        options={{
                          style: {
                            base: {
                              iconColor: "black",
                              color: "black",
                              fontFamily: themeFonts["Poppins-Regular"],
                              fontSize: "14px",
                              fontSmoothing: "antialiased",
                              ":-webkit-autofill": {
                                color: "#fce883",
                              },
                              "::placeholder": {
                                color: "black",
                                fontFamily: themeFonts["Poppins-Regular"],
                              },
                            },
                            invalid: {
                              iconColor: "red",
                              color: "red",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  <Box sx={{ width: "380px" }}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#0000004D",
                        textAlign: "start",
                        fontFamily: themeFonts["Poppins-Medium"],
                      }}
                    >
                      CVC
                    </Typography>
                    <CardCvcElement
                      // onChange={handleCardCvcChange}
                      options={{
                        style: {
                          base: {
                            iconColor: "black",
                            color: "black",
                            fontFamily: themeFonts["Poppins-Regular"],
                            fontSize: "14px",
                            fontSmoothing: "antialiased",
                            ":-webkit-autofill": {
                              color: "#fce883",
                            },
                            "::placeholder": {
                              color: "black",
                              fontFamily: themeFonts["Poppins-Regular"],
                            },
                          },
                          invalid: {
                            iconColor: "red",
                            color: "red",
                          },
                        },
                        placeholder: "---",
                      }}
                    />
                  </Box>
                  {error && <div>{error}</div>}
                </Box>
              </form>
            ) : (
              <>
                {total && (
                  <>
                    <Box sx={{ padding: "32px 0px 24px 2px" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontFamily: themeFonts["Poppins-Bold"],
                          textAlign: "center",
                          paddingBottom: "22px",
                        }}
                      >
                        Select payment method
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "32px",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          sx={{
                            backgroundColor: "#F0F0F0",
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "12px",
                            color: "#000000",
                            width: "175px",
                            padding: "0px",
                            height: "36px",
                            border:
                              selectedPaymentOption === "debit_cards"
                                ? "2px solid #00ADB5"
                                : "0px solid #F0F0F0",
                            fontFamily: themeFonts["Poppins-Bold"],
                            "&:hover": {
                              border: "2px solid #00ADB5",
                              background: themeColors["#F0F0F0"],
                            },
                          }}
                          onClick={() => handlePaymentMethod("debit_cards")}
                        >
                          <AtmCardIcon />
                          Debit or Credit card
                        </Button>
                        {/* <Button
                      sx={{
                        backgroundColor: "#FFCC33",
                        width: "175px",
                        padding: "0px",
                        height: "36px",
                        border:
                          selectedPaymentOption === "paypal"
                            ? "2px solid #00ADB5"
                            : "0px solid #F0F0F0",
                        "&:hover": {
                          background: themeColors["#FFCC33"],
                          border: "2px solid #00ADB5",
                        },
                      }}
                      onClick={() => handlePaymentMethod("paypal")}
                    >
                      <PayPalIcon />
                     </Button>  */}

                        <PayPalButton
                          amount={total}
                          credits={credits}
                          userId={userId}
                          handleCloseButton={handleCloseButton}
                          creditsRefetch={creditsRefetch}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <StripeIcon />
                    </Box>
                  </>
                )}
              </>
            )}

            <Box
              sx={{
                display: "flex",
                gap: "18px",
                justifyContent: "center",
                marginTop: "16px",
                color: "#00ADB5",
                fontSize: "16px",
              }}
            >
              <Typography sx={{ fontFamily: themeFonts["Poppins-SemiBold"] }}>
                Total
              </Typography>
              <Typography
                sx={{
                  fontFamily: themeFonts["Poppins-SemiBold"],
                  textDecoration: "underline",
                }}
              >
                ${total}
              </Typography>
            </Box>

            {isOpen === true && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    height: "auto",
                    fontSize: "14px",
                    backgroundColor: "#00ADB5",
                    fontFamily: themeFonts["Poppins-SemiBold"],
                    color: "#fff",
                    margin: "32px 0 8px 0",
                    "&:hover": {
                      background: "#00ADB5",
                    },
                  }}
                  onClick={handleSubmit}
                >
                  {loading ? "Processing..." : "Purchase"}
                </Button>
                <Button
                  onClick={handleClose}
                  sx={{
                    fontSize: "12px",
                    color: "#00ADB5",
                    fontFamily: themeFonts["Poppins-Regular"],
                    textDecoration: "underline #00ADB5 ",
                    height: "auto",
                    padding: "0px",
                    "&:hover": {
                      background: "unset",
                    },
                  }}
                >
                  Select other payment option
                </Button>
              </Box>
            )}
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
}

export default SelectMoreSubmissions;
