import { emptySplitApi as api } from "./emptyApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGenresList: builder.query<any, void>({
      query: () => {
        return {
          url: `/api/genres`,
        };
      },
    }),
    updateGenres: builder.mutation<any, Partial<GenresApiArg>>({
      query: (queryArg) => {
        return {
          url: `/api/playlist/update`,
          method: "PUT",
          body: queryArg,
        };
      },
    }),
  }),
});
export type GenresApiArg = {
  id?: any;
  genres?: any;
  isActive?: any;
};

export { injectedRtkApi as enhancedApi };

export const { useGetGenresListQuery, useUpdateGenresMutation } =
  injectedRtkApi;
