import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Link as MuiLink,
  IconButton,
} from "@mui/material";
import { LogBackground3 } from "../pngs";
import { themeFonts } from "../configs";
// import { SpotifyIcon, EmailIcon, InstagramIcon, MainLogo, WhiteLogo } from "../svgs";
import { Link } from "react-router-dom";
import { ROUTES } from "../components/consts/routes.consts";
import { EmailIcon, InstagramIcon, MainLogo, SpotifyIcon } from "../svgs";

const linkStyle = {
  color: "white",
  textDecoration: "none",
};

export const Footer = () => {
  const isSmallScreen = useMediaQuery("(max-width:700px)");

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/pitchplaylists_/", "_blank");
  };

  const handleSpotifyClick = () => {
    window.open(
      "https://open.spotify.com/user/3147o6eymszs3p5ro6itbfw7qtj4",
      "_blank"
    );
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          paddingLeft: "0 !important",
          paddingTop: isSmallScreen ? "30px" : "70px !important",
          alignItems: "center",
          background: `url(${LogBackground3})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            alignItems: "flex-start",
            width: "100%",
            gap: isSmallScreen ? "20px" : "",
          }}
        >
          <Link to="/">
            <MainLogo width={"250px"} />
            {/* <WhiteLogo/> */}
            {/* <AuthLogo width={"100%"}/> */}
          </Link>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: isSmallScreen ? "20px" : "64px",
              marginBottom: isSmallScreen ? "40px" : "80px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: "40px",
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: isSmallScreen ? "20px" : "22px",
                  fontFamily: themeFonts["Poppins-SemiBold"],
                }}
              >
                Contact Us
              </Typography>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: isSmallScreen ? "14px" : "16px",
                  fontFamily: themeFonts["Poppins-SemiBold"],
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                Email
                <MuiLink
                  component={Link}
                  to={"mailto:info@pitchplaylists.com"}
                  sx={{
                    color: "#66FFBD",
                    fontSize: "14px",
                    fontFamily: themeFonts["Poppins-Regular"],
                    cursor: "pointer",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  info@pitchplaylists.com
                </MuiLink>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "7px",
                  marginLeft: "-6px",
                }}
              >
                {/* <IconButton
                  onClick={handleSpotifyClick}
                  // sx={{
                  //   "& svg path": {
                  //     height: "20px !important",
                  //     width: "20px !important",
                  //   },
                  // }}
                >
                  <SpotifyIcon height={25} width={25} />
                </IconButton> */}

                <IconButton
                  component={Link}
                  to={"mailto:info@pitchplaylists.com"}
                  sx={{
                    "& svg path": {
                      height: "20px !important",
                      width: "25px !important",
                    },
                  }}
                >
                  <EmailIcon height={25} width={25} />
                </IconButton>

                <IconButton
                  onClick={handleInstagramClick}
                  sx={{
                    "& svg path": {
                      height: "25px !important",
                      width: "25px !important",
                    },
                  }}
                >
                  <InstagramIcon height={25} width={25} />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: "18px",
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: isSmallScreen ? "20px" : "22px",
                  fontFamily: themeFonts["Poppins-SemiBold"],
                  marginBottom: "20px",
                }}
              >
                Site Layout
              </Typography>
              <MuiLink
                component={Link}
                to={ROUTES.SUBMIT_A_SONG}
                sx={{
                  textDecoration: "none",
                  color: "#FFFFFF",
                  fontSize: isSmallScreen ? "14px" : "16px",
                  fontFamily: themeFonts["Poppins-SemiBold"],
                }}
              >
                Submit a song
              </MuiLink>

              <MuiLink
                component={Link}
                to={ROUTES.SUBMIT_A_PLAYLIST}
                sx={{
                  textDecoration: "none",
                  color: "#FFFFFF",
                  fontSize: isSmallScreen ? "14px" : "16px",
                  fontFamily: themeFonts["Poppins-SemiBold"],
                }}
              >
                Submit a playlist
              </MuiLink>

              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: isSmallScreen ? "14px" : "16px",
                  fontFamily: themeFonts["Poppins-SemiBold"],
                }}
              >
                <Link to="/faq" style={linkStyle}>
                  {" "}
                  FAQ
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            padding: "24px",
            borderTop: "1px solid #FFFFFF4D",
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: "16px",
              fontFamily: themeFonts["Poppins-SemiBold"],
              display: "contents",
            }}
          >
            © PitchPlaylists 2024  ·  
            <Link to="/terms-and-conditions" style={linkStyle}>
              {" "}
              Privacy and Terms
            </Link>
          </Typography>
        </Box>
      </Grid>
    </>
  );
};
