import { PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import {
  useCreateTransactionOrderMutation,
  useCreateTransactionOrderCaptureMutation,
} from "../../components/apis/transactionApi";

interface PayPalButtonProps {
  amount: string;
  credits: number;
  userId: string;
  handleCloseButton?: any;
  creditsRefetch?: any;
}

export const PayPalButton: React.FC<PayPalButtonProps> = ({
  amount,
  credits,
  userId,
  handleCloseButton,
  creditsRefetch,
}) => {
  const [transactionOrder] = useCreateTransactionOrderMutation();
  const [transactionOrderCapture] = useCreateTransactionOrderCaptureMutation();

  return (
    <PayPalButtons
      style={{
        layout: "vertical",
      }}
      createOrder={async (data: any, actions: any) => {
        const res = await transactionOrder({ amount }).unwrap();
        return res?.id;
      }}
      onApprove={async (data: { orderID: any }, actions: any) => {
        try {
          await transactionOrderCapture({
            orderID: data.orderID,
            amount,
            credits,
            paymentMethod: "paypal",
            userId,
          }).unwrap();
          toast.success("Success");
        } catch (error: any) {
          toast.error("Failed");
        }
        handleCloseButton();
        creditsRefetch();
      }}
    />
  );
};
