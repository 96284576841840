import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
} from "@mui/material";
import { themeColors, themeFonts } from "../../configs";
import { EditGatesArrowIcon } from "../../svgs";
export const CustomAccordion = ({
  title,
  description,
  label,
  value,
  onChange,
  isInvalid,
}: {
  title: any;
  description: any;
  label: any;
  value?: any;
  onChange?: any;
  isInvalid?: any;
}) => {
  return (
    <>
      <Accordion
        sx={{
          " &.MuiPaper-root.MuiAccordion-root": {
            border: "1px solid #00ADB5CC",
            boxShadow: "none",
            borderRadius: "5px",
            margin: "0px",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<EditGatesArrowIcon />}
          // aria-controls="panel1-content"
          // id="panel1-header"
          sx={{
            "&.MuiAccordionSummary-root.MuiButtonBase-root": {
              padding: "10px !important",
              alignItems: "flex-start",
            },
            "& .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters":
              {
                display: "flex",
                flexDirection: "column",
                margin: 0,
              },
            "& .MuiAccordionSummary-expandIconWrapper": {
              transform: "rotate(270deg)",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(0deg)",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: themeFonts["Poppins-SemiBold"],
              color: themeColors["#00ADB5"],
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "10px",
              fontFamily: themeFonts["Poppins-Regular"],
              color: themeColors["#00000080"],
            }}
          >
            {description}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "&.MuiAccordionDetails-root": {
              padding: "0px 10px 10px 10px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              fontFamily: themeFonts["Poppins-Regular"],
              color: themeColors["#00000080"],
            }}
          >
            {label}
          </Typography>
          <TextField
            variant="outlined"
            sx={{
              width: "100%",
              marginTop: "8px",

              height: "29px",
              "& .MuiInputBase-root": {
                height: "29px",
                "& input": {
                  padding: "0 12px",
                },
                fontFamily: themeFonts["Poppins-Regular"],
                fontSize: "12px",
                color: isInvalid ? "red" : themeColors["#00000080"],
              },
              "& fieldset.MuiOutlinedInput-notchedOutline": {
                border: isInvalid
                  ? "1px solid red !important"
                  : "1px solid #00000080 !important",
                borderRadius: "5px",
                "&:hover": {
                  border: "1px solid #00000080 !important",
                  borderRadius: "5px",
                },
              },
            }}
            value={value}
            onChange={onChange}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
