import {
  Box,
  FormHelperText,
  InputProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";

import { ReactNode } from "react";
import { themeColors, themeFonts } from "../../configs";

interface CustomFilledInputProps extends InputProps {
  label?: string;
  placeholder?: string;
  value?: any;
  errorStatus?: string;
  disableValue?: boolean;
  disabled?: boolean;
  titleComponent?: ReactNode;
  formatValue?: (value: string) => string;
  onChangeValue?: (e: string) => void;
  textFieldProps?: TextFieldProps;
  direction?: string;
  height?: string;
  border?: string;
  fontSize?: string;
  fontWeight?: string;
  paddingTop?: any;
  backgroundColor?: any;
  color?: any;
  borderRadius?: any;
  Color?: any;
  alignItems?: any;
  marginTop?: any;
  onBlur?: any;
  helperText?: any;
  helperTextProps?: any;
  fontFamily?: keyof typeof themeFonts;
  type?: any;
}
export const CustomFilledInput = ({
  label,
  placeholder = label,
  value = "",
  onChangeValue,
  errorStatus,
  disableValue,
  disabled,
  formatValue = (value) => value,
  titleComponent,
  textFieldProps,
  height,
  border,
  fontSize,
  fontWeight,
  paddingTop,
  backgroundColor,
  color,
  Color,
  borderRadius,
  alignItems,
  marginTop,
  onBlur,
  helperText,
  helperTextProps,
  direction = "ltr",
  fontFamily,
  type,
  ...rest
}: CustomFilledInputProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: paddingTop ? { paddingTop } : "",
        position: "relative",
      }}
    >
      {label && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: fontFamily
                ? fontFamily
                : themeFonts["Poppins-Regular"],
              color: themeColors["#0000004D"],
              fontSize: fontSize ? { fontSize } : 12,
            }}
          >
            {label}
          </Typography>
          {titleComponent}
        </Box>
      )}

      <Box>
        <TextField
          sx={{
            "&.MuiFormControl-root.MuiTextField-root": {
              width: "100%",
              "& ::placeholder": {
                fontFamily: themeFonts["Poppins-Regular"],
                color: themeColors["#000000"],
                fontSize: "14px",
                opacity: 1,
              },
            },
            "& .MuiInputBase-root.MuiInput-root.MuiInput-underline": {
              paddingBottom: "4px",
              fontFamily: themeFonts["Poppins-Regular"],
              color: themeColors["#000000"],
              fontSize: "14px",
            },
            "& input:-internal-autofill-selected": {
              backgroundColor: "transparent !important",
            },

            "& ::after": {
              borderBottom: "0px",
            },
            fontFamily: themeFonts["Poppins-Regular"],
            color: themeColors["#000000"],
            fontSize: "14px",
          }}
          variant="standard"
          placeholder={placeholder}
          value={!disableValue ? formatValue(value) : undefined}
          onChange={(e) => {
            if (onChangeValue) onChangeValue(e.target.value);
          }}
          onBlur={() => {
            if (onChangeValue) onChangeValue(value.trim());
          }}
          error={!!errorStatus}
          inputProps={{
            ...textFieldProps?.inputProps,
          }}
          type={type}
        />
      </Box>

      {!!helperText && (
        <FormHelperText
          sx={{
            fontFamily: themeFonts["Poppins-Bold"],
            color: themeColors["#FF3939"],
            fontSize: 10,
            marginLeft: 0,
            position: "absolute",
            bottom: "-17px",
          }}
          {...helperTextProps}
        >
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};
