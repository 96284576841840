import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import { AuthMainLayout } from "./auth-main.layout";
import { AuthHeaderLayout } from "./auth-header-layout";
import { ROUTES } from "../../consts/routes.consts";

export const AuthLayout = () => {
  const location = useLocation();
  const auth = "Auth";
  const isDashboardPage =
    location.pathname === ROUTES.DASHBOARD ||
    location.pathname === ROUTES.ACCOUNT ||
    location.pathname === ROUTES.SUBMIT_A_SONG_PLAYLIST;
  const isSubmitPage =
    location.pathname === ROUTES.SUBMIT_A_SONG ||
    location.pathname === ROUTES.SUBMIT_A_SONG_1 ||
    location.pathname === ROUTES.SUBMIT_A_PLAYLIST ||
    location.pathname === ROUTES.CONFIRM_PLAYLIST;
  return (
    <Box sx={{ display: "flex" }}>
      <AuthHeaderLayout
        auth={auth}
        isDashboardPage={isDashboardPage}
        isSubmitPage={isSubmitPage}
      />

      <AuthMainLayout>
        <Outlet />
      </AuthMainLayout>
    </Box>
  );
};
