import { Box, Dialog, Typography } from "@mui/material";
import React from "react";
import { themeFonts, themeColors } from "../../configs";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export const ConfirmationPopup = (props: any) => {
  const { hasOpen, handleClose } = props;
  return (
    <Box>
      <Box sx={{ float: "right" }}>
        <Dialog
          onClose={handleClose}
          open={hasOpen}
          sx={{
            "& .MuiPaper-root.MuiPaper-elevation": {
              borderRadius: "0px",
              boxShadow: "4px 4px 4px 0px #0000001A",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: "24px 44px",
              textAlign: "center",
              borderRadius: "12px",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                color: "red",
                height: "50px",
                width: "50px",
              }}
            />
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: themeFonts["Poppins-Regular"],
                color: themeColors["#000000"],
                marginTop: "12px",
              }}
            >
              Your Account is Deleted!.
            </Typography>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};
