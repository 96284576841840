import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getArtistList: builder.query<[], Partial<ArtistListApiArg>>({
      query: (queryArg) => {
        return {
          url: `/api/artists/songs`,
          params: queryArg,
        };
      },
    }),
    getTrackById: builder.query<[], Partial<ArtistListApiArg>>({
      query: (queryArg) => {
        return {
          url: `/api/artists/submitted-track`,
          params: queryArg,
        };
      },
    }),
    createTrack: builder.mutation<any, ArtistListApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/artists/submit-songs`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
  }),
});
export { injectedRtkApi as enhancedApi };
export type ArtistListApiArg = {
  trackId?: string;
  playlistIds?: any;
  id?: any;
  userId?: any;
};
export const {
  useGetArtistListQuery,
  useGetTrackByIdQuery,
  useCreateTrackMutation,
} = injectedRtkApi;
