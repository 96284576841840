import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { UnauthHeaderLayout } from "./unauth-header-layout";
import { UnauthMainLayout } from "./unauth-main.layout";
import { ROUTES } from "../../consts/routes.consts";

export const UnauthLayout = () => {
  const location = useLocation();
  const isSubmitPage =
    location.pathname === ROUTES.SUBMIT_A_SONG ||
    location.pathname === ROUTES.SUBMIT_A_SONG_1 ||
    location.pathname === ROUTES.SUBMIT_A_PLAYLIST ||
    location.pathname === ROUTES.CONFIRM_PLAYLIST;
  return (
    <Box sx={{ display: "flex" }}>
      <UnauthHeaderLayout isSubmitPage={isSubmitPage} />

      <UnauthMainLayout>
        <Outlet />
      </UnauthMainLayout>
    </Box>
  );
};
