import { Box, Chip, Grid, Typography, useMediaQuery } from "@mui/material";
import { themeColors, themeFonts } from "../../configs";
import { ChipImg2 } from "../../pngs";
import { Booster, GiftBox, PlayButton, SongPitch } from "../../svgs";
const dummyData = [
  {
    id: 1,
    image: <SongPitch />,
    title: "Easy Song Pitch",
    description: "Effortlessly send songs to playlists with our smooth tool",
  },
  {
    id: 2,
    image: <GiftBox />,
    title: "Curator contest",
    description: "Boost your fan engagement by over giveaways and contests",
  },
  {
    id: 3,
    image: <Booster />,
    title: "It's fast and simple",
    description:
      "Submit songs and playlists in minutes with our user friendly platform",
  },
  {
    id: 4,
    image: <PlayButton />,
    title: "Connect with Curators",
    description:
      "Submit to high quality and vetted curators with impactful audiences",
  },
];

export const Features = () => {
  const isMiniScreen = useMediaQuery("(max-width:327px)");
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          paddingLeft: "0 !important",
          paddingY: "80px !important",
          background: themeColors["#FFFFFF"],
        }}
      >
        <Chip
          label="Features"
          sx={{
            color: "#FFFFFF",
            fontSize: "18px",
            fontFamily: themeFonts["Poppins-Medium"],
            background: `url(${ChipImg2})`,
            height: "35px",
            paddingX: "51px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
        <Typography
          sx={{
            color: "#000000",
            fontSize: "32px",
            fontFamily: themeFonts["Poppins-SemiBold"],
            marginTop: "16px",
            maxWidth: 652,
            textAlign: "center",
          }}
        >
          Top Features That Make Us Stand Out
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "32px",
            maxWidth: "1030px",
            justifyContent: "center",
            marginY: "40px",
          }}
        >
          {dummyData.map((val) => (
            <Box
              key={val.id}
              sx={{
                background: themeColors["#EEEEEE99"],
                width: isMiniScreen ? "auto" : "327px",
              }}
            >
              <Box
                sx={{
                  width: isMiniScreen ? "auto" : "327px",
                  paddingX: isMiniScreen ? "20px" : "37px",
                  paddingY: "43px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                {val.image}
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "20px",
                    fontFamily: themeFonts["Poppins-SemiBold"],
                    maxWidth: 652,
                    textAlign: "center",
                  }}
                >
                  {val.title}
                </Typography>
                <Typography
                  sx={{
                    color: "#00000099",
                    fontSize: "16px",
                    fontFamily: themeFonts["Poppins-Regular"],
                    maxWidth: 652,
                    textAlign: "center",
                  }}
                >
                  {val.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  );
};
