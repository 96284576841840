import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { themeFonts } from "../../../configs";

function createData(
  name: string,
  purpose: string,
  dataCollection: string,

) {
  return { name, purpose, dataCollection, };
}

const rows = [
  createData(
    "A. Identifiers.",
    "A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.",
    "NO",
  ),
  createData(
    "B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).",
    "A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. ",
    "NO",
   
  ),

  createData(
    "C. Protected classification characteristics under California or federal law.",
    "Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth, and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).",
    "NO",
   
  ),
  createData(
    "D. Commercial information.",
    "Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.",
    "NO",
   
  ),

  createData(
    "E. Biometric information.",
    "Genetic, physiological, behavioral, and biological characteristics or activity patterns used to extract a template or other identifier or identifying information, such as fingerprints, faceprints, voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.",
    "NO",
   
  ),

  
  createData(
    "F. Internet or other similar network activity.",
    "Browsing history, search history, and information on a consumer's interaction with a website, application, or advertisement",
    "YES",
   
  ),

  createData(
    "G. Geolocation data.",
    "Physical location or movements. ",
    "NO",
   
  ),

  createData(
    "H. Sensory data.",
    "Audio, electronic, visual, thermal, olfactory, or similar information.",
    "NO",
   
  ),

  createData(
    "I. Professional or employment-related information.",
    "Current or past job history or performance evaluations.",
    "NO",
   
  ),

  createData(
    "J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).",
    "Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.",
    "NO",
  ),

  createData(
    "K. Inferences drawn from other personal information.",
    "Profile reflects a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.",
    "NO",
  ),
];

export default function PrivacySectionTableThree() {
  return (
    <TableContainer
      component={Paper}
      sx={{
        "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1":
          {
            boxShadow: "0",
            borderRadius: "0px",
          },
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: "#EFEFEF", color: "#000000 !important", }}>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                 fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
             Category
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
             Examples
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid #00000040",
                textAlign: "center",
                color: "#000000 !important",
                fontSize: "13px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
             Collected
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{}}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.purpose}
              </TableCell>
              <TableCell
                sx={{
                  border: "0.5px solid #00000040",
                  textAlign: "center",
                  color: "#00000080 !important",
                  fontSize: "12px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                {row.dataCollection}
              </TableCell>
          
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
