import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, Grid } from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  useGetGenresListQuery,
  useUpdateGenresMutation,
} from "../apis/genresData";
import { CustomCheckbox } from "../checkbox";
import { CrossIcon } from "../../svgs";
import { themeFonts } from "../../configs";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type PopupDataProps = {
  handleCloseData: () => void;
  isOpen: boolean;
  setSelectedGenres?: any;
  selectedGenres?: any;
  editedData?: any;
  refetch?: any;
  playlistId?: any;
};

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#000000" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    marginTop: "5px",
    marginBottom: "5px",
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function groupBy(array: any[], key: string) {
  return array.reduce((result, item) => {
    const groupKey = item[key];

    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey].push(item);
    return result;
  }, {});
}

const GenresData: React.FC<PopupDataProps> = ({
  handleCloseData,
  isOpen,
  setSelectedGenres,
  selectedGenres,
  editedData,
  refetch,
  playlistId,
}) => {
  const { data: genres } = useGetGenresListQuery();
  const sortedGenres = genres
    ? [...genres].sort((a: any, b: any) => {
        return a.categoryIndex - b.categoryIndex;
      })
    : [];

  const groupedData = sortedGenres ? groupBy(sortedGenres, "category") : {};
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  useEffect(() => {
    if (editedData) {
      setSelectedGenres(editedData);
    }
  }, [editedData, setSelectedGenres]);
  const handleCheckboxChange = (genre: any) => {
    setSelectedGenres((prevSelected: any[]) => {
      if (
        prevSelected.some((selected: { id: any }) => selected.id === genre.id)
      ) {
        return prevSelected.filter(
          (selected: { id: any }) => selected.id !== genre.id
        );
      } else {
        return [...prevSelected, genre];
      }
    });
  };

  const isChecked = (genre: any) => {
    return selectedGenres.some(
      (selected: { id: any }) => selected.id === genre.id
    );
  };
  const [updateGenres, { isLoading }] = useUpdateGenresMutation();
  const handleSave = async () => {
    if (editedData) {
      try {
        await updateGenres({
          id: playlistId,
          genres: selectedGenres,
          isActive: true,
        }).unwrap();
        toast.success("Success");
      } catch (error: any) {
        toast.error("Failed");
      }
      refetch();
    }

    handleCloseData();
  };

  const handleRemoveGenre = (genreToRemove: any) => {
    setSelectedGenres((prevSelected: any[]) =>
      prevSelected.filter((genre: { id: any }) => genre.id !== genreToRemove.id)
    );
  };
  const disabled = selectedGenres.length === 0;
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleCloseData}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <Grid container spacing={0}>
          <Grid item sx={{ width: "350px", height: "auto", padding: "20px" }}>
            <Grid item xs={12}>
              <DialogActions sx={{ padding: "0px !important" }}>
                <Button
                  onClick={handleSave}
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: disabled ? "#00ADB5 !important" : "#FFFFFF",
                    border: "2px solid #00ADB5",
                    background: disabled ? "#FFFFFF" : "#00ADB5",
                    paddingX: "10px",
                    paddingY: "0px",
                    minWidth: "0px",
                    height: "auto",
                    "&:hover": {
                      color: disabled ? "#00ADB5 !important" : "#FFFFFF",
                      border: "2px solid #00ADB5",
                      background: disabled ? "#FFFFFF" : "#00ADB5",
                    },
                  }}
                  disabled={disabled}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={17}
                      sx={{
                        color: "inherit",
                        fontWeight: "900",
                      }}
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </DialogActions>
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#000000",
                  marginTop: "0px",
                  lineHeight: "27px",
                }}
              >
                Select Genres
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "400",
                  color: "#00000080",
                  marginTop: "0px",
                  lineHeight: "15px",
                  fontFamily: "Poppins-Regular",
                  marginBottom: "13px",
                }}
              >
                Select upto 5 genres
              </Typography>

              {selectedGenres?.map(
                (selectedGenres: any, index: React.Key | null | undefined) => {
                  return (
                    <>
                      <Box
                        key={index}
                        sx={{ display: "inline-block", margin: "4px" }}
                      >
                        <Button
                          onClick={() => handleRemoveGenre(selectedGenres)}
                          sx={{
                            backgroundColor: "#00ADB5",
                            fontSize: "12px",
                            fontFamily: themeFonts["Poppins-Regular"],
                            color: "#FFFFFF",
                            gap: "13px",
                            height: "auto",
                            padding: "5px 12px",
                            "&:hover": {
                              backgroundColor: "#00ADB5",
                              color: "#FFFFFF",
                            },
                          }}
                        >
                          {selectedGenres.name} <CrossIcon />
                        </Button>
                      </Box>
                    </>
                  );
                }
              )}

              {Object.keys(groupedData).map((category, index) => {
                return (
                  <div key={index}>
                    <Accordion
                      sx={{ border: "none" }}
                      expanded={expanded === category}
                      onChange={handleChange(category)}
                    >
                      <AccordionSummary
                        sx={{
                          backgroundColor: "unset",
                          padding: "0px",
                          color: "#000000",
                          minHeight: "30px",
                        }}
                        aria-controls={`${category}-content`}
                        id={`${category}-header`}
                      >
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: "13px",
                            fontWeight: "600",
                            lineHeight: "18px",
                          }}
                        >
                          {category}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "auto",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "18px",
                            color: "#00000080",
                          }}
                        >
                          ({groupedData[category].length})
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ border: "0px" }}>
                        {groupedData[category]
                          .slice()
                          .sort((a: any, b: any) => a.nameIndex - b.nameIndex)
                          .map((genre: any, index: any) => (
                            <div key={index}>
                              <CustomCheckbox
                                sx={{
                                  marginTop: "10px",
                                  "& svg": { fontSize: "16px" },
                                  "& svg.MuiSvgIcon-root path": {
                                    border: "1px solid #00000080 !important",
                                    fill: "#00ADB5",
                                    borderRadius: "3px",
                                  },
                                }}
                                checked={isChecked(genre)}
                                onChange={() => handleCheckboxChange(genre)}
                                label={genre.name}
                                disabled={
                                  !isChecked(genre) &&
                                  selectedGenres.length >= 5
                                }
                              />
                            </div>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default GenresData;
