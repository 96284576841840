import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsersList: builder.query<[], void>({
      query: () => {
        return {
          url: `/api/user/already-registered`,
        };
      },
    }),
    getMeInfo: builder.query<[], any>({
      query: (queryArg) => {
        return {
          url: `/api/user/me`,
          params: queryArg,
        };
      },
    }),
    createUser: builder.mutation<any, Partial<[]>>({
      query: (item) => {
        return {
          url: `/api/user`,
          method: "POST",
          body: item,
        };
      },
    }),
  }),
});
export { injectedRtkApi as enhancedApi };

export type UsersApiArg = {
  id?: number;
};
export const { useGetUsersListQuery, useGetMeInfoQuery, useCreateUserMutation } = injectedRtkApi;
