import { ReactNode } from "react";
import { ROUTES } from "../components/consts/routes.consts";

export interface IMenuList {
  icon?: ReactNode;
  title?: string;
  route?: string;
}

export const navbarMenuConfig = [
  {
    title: "Top Curator Chart",
    route: ROUTES.TOP_CURATOR_CHART,
  },
  {
    title: "FAQ",
    route: ROUTES.FAQ,
  },
];
