import { AppBar, Box, Button, Toolbar, useMediaQuery } from "@mui/material";

import { useState } from "react";
import { NavbarMenu } from "../../navbar";
import { themeFonts } from "../../../configs";
import { SpotifyWhiteIcon } from "../../../pngs";
// import { useLoginWithSpotifyMutation } from "../../../store/api/auth/auth.api";

export const UnauthHeaderLayout = ({
  isSubmitPage,
}: {
  isSubmitPage?: any;
}) => {
  const [colorChange, setColorChange] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:1100px)");
  // const [loginWithSpotify] = useLoginWithSpotifyMutation();

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleConnect = async () => {
    // window.location.href = "http://localhost:9090/api/auth/login";
    // window.location.href =
    // "https://pitch-playlist-api.vercel.app/api/auth/login";
    window.location.href = "https://api.pitchplaylists.com/api/auth/login";
  };

  return (
    <>
      <AppBar
        sx={{
          width: `auto`,
          left: "0px",
          backgroundColor: colorChange ? "#000000" : "transparent",
          boxShadow: "none",
          position: "fixed",
          height: isSmallScreen ? "65px" : "80px !important",
        }}
      >
        <Toolbar
          sx={{
            height: isSmallScreen ? "65px !important" : "80px !important",
            boxShadow: colorChange
              ? "0px 3px 6px 0px rgb(0 0 0 / 16%)"
              : "none",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: isSmallScreen
              ? "0 !important"
              : "1px solid rgb(255 255 255 / 30%)",
            paddingX: isSmallScreen ? "0 !important" : "130px !important",
          }}
        >
          {isSmallScreen ? (
            <Button
              onClick={handleConnect}
              sx={{
                display: "flex",
                fontSize: "16px",
                fontFamily: themeFonts["Poppins-Medium"],
                background: "#1DB954",
                color: "#ffffff",
                padding: "11px 22px",
                borderRadius: "0px",
                width: "100%",
                height: "100%",
                "&:hover": {
                  background: " #1db954",
                },
              }}
            >
              <Box
                sx={{
                  height: "46px",
                  width: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={SpotifyWhiteIcon}
                  alt=""
                  style={{ width: "23px", height: "23px" }}
                />
              </Box>
              Connect with Spotify
            </Button>
          ) : (
            <NavbarMenu
              handleConnect={handleConnect}
              isSubmitPage={isSubmitPage}
            />
          )}
        </Toolbar>
      </AppBar>
      {isSmallScreen && (
        <AppBar
          sx={{
            width: `auto`,
            left: "0px",
            backgroundColor: colorChange || open ? "#000000" : "transparent",
            boxShadow: "none",
            position: "fixed",
            height: "80px !important",
            top: "65px",
          }}
        >
          <Toolbar
            sx={{
              height: "80px !important",
              boxShadow: colorChange
                ? "0px 3px 6px 0px rgb(0 0 0 / 16%)"
                : "none",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid rgb(255 255 255 / 30%)",
              paddingX: isSmallScreen ? "30 !important" : "130px !important",
            }}
          >
            <NavbarMenu
              isSmallScreen={isSmallScreen}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleConnect={handleConnect}
              isSubmitPage={isSubmitPage}
            />
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};
