import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  useMediaQuery,
  Link as MuiLink,
} from "@mui/material";
import { Footer } from "../footer";
import {
  Avatar,
  LogBackground3,
  SpotifyGreenLogo,
  SpotifyWhiteLogo,
} from "../../pngs";
import { SearchComponents } from "../../components/filter/search-component";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";

import {
  EqualToIcon,
  GreenArrow,
  GreenDot,
  InformationIcon,
  KingIcon,
  LeftArrow,
  RedArrow,
  RightArrow,
  TrophyIcon,
} from "../../svgs";
import { themeColors, themeFonts } from "../../configs";
import { ROUTES } from "../../components/consts/routes.consts";
import { Link, useNavigate } from "react-router-dom";
import { useGetCuratorsListQuery } from "../../components/apis/curatorsApi";
import { CustomDatePicker } from "../../components/calendar/custom-calendar";
import { useGetCalendarValueQuery } from "../../components/apis/topCuratorsApi";
import dayjs from "dayjs";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: "0px !important",
    marginBottom: "18px !important",
    boxShadow: "0px 6px 6px 0px rgb(0 0 0 / 10%)",
    border: "0.5px solid #00000033 !important",
    background: "#FFFFFF",
    color: "#000000",
    textAlign: "center",
    width: "267px",
    borderRadius: "0px",
    padding: "10px 10px 16px 10px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
    fontSize: "26px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  paddingTop: "0px",
  marginTop: "0px",
  "&:hover": {
    backgroundColor: "unset",
  },
  "& svg path": {
    fill: "White",
  },
}));

const TopChart = () => {
  const [date, setDate] = React.useState<any>(dayjs());
  const currentDate = dayjs();

  const getLastWeekDates = (date: any) => {
    const currentDate = dayjs(date);
    const currentDay = currentDate.day();
    const lastSunday =
      currentDay === 0
        ? currentDate.subtract(7, "day")
        : currentDate.subtract(currentDay, "day");

    const lastMonday = lastSunday.subtract(6, "day");
    const formattedLastMonday = lastMonday.format("D MMM YYYY");
    const formattedLastSunday = lastSunday.format("D MMM YYYY");

    return `${formattedLastMonday} - ${formattedLastSunday}`;
  };

  const handleDateChange = (newDate: any) => {
    if (newDate && newDate.isValid()) {
      setDate(newDate);
    }
  };

  const handlePreviousDay = () => {
    const newDate = date.subtract(1, "day");
    handleDateChange(newDate);
  };

  const handleNextDay = () => {
    const newDate = date.add(1, "day");
    if (newDate.isBefore(currentDate) || newDate.isSame(currentDate, "day")) {
      handleDateChange(newDate);
    }
  };

  const formattedDate = date.format("DD-MM-YYYY");

  const isNextDayDisabled = date.isSame(currentDate, "day");

  const { data } = useGetCalendarValueQuery<any>({ date: formattedDate });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery("(max-width:1400px)");
  const isSmallScreen = useMediaQuery("(max-width:1050px)");
  const smallScreen = useMediaQuery("(max-width:740px)");
  const playlist = "https://open.spotify.com/playlist";
  const [searchInput, setSearchInput] = React.useState<string>("");
  const parts = searchInput.split("/");
  const lastPart = parts[parts.length - 1];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [playlistId, queryString] = lastPart.split("?");
  const { refetch } = useGetCuratorsListQuery<any>(
    { playlistId: playlistId },
    {
      skip: !searchInput.includes(playlist),
    }
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const token = localStorage.getItem("token");
  const handleConnect = async () => {
    //  window.location.href = `http://localhost:9090/api/auth/login?playlistId=${playlistId}`;
    // window.location.href = `https://pitch-playlist-api.vercel.app/api/auth/login?playlistId=${playlistId}`;
    window.location.href = `https://api.pitchplaylists.com/api/auth/login?playlistId=${playlistId}`;
  };
  React.useEffect(() => {
    if (searchInput.includes(playlist)) {
      if (token) {
        refetch({ playlistId: playlistId }).then((response: { data: any }) => {
          navigate(ROUTES.CONFIRM_PLAYLIST, {
            state: { playlistData: response.data },
          });
        });
      } else {
        handleConnect();
      }
    } else {
      setTimeout(() => {
        setSearchInput("");
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, searchInput]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const sortedData = data && [...data].sort((a, b) => a.position - b.position);
  const isInvalid = searchInput !== "" && !searchInput.includes(playlist);
  const handleSearchChange = (value: React.SetStateAction<string>) => {
    setSearchInput(value);
  };

  const getTimeUntilNextMonday = () => {
    const now: any = new Date();
    const nextMonday: any = new Date(now);
    nextMonday.setDate(now.getDate() + ((1 + 7 - now.getDay()) % 7 || 7));
    nextMonday.setHours(0, 0, 0, 0);
    return nextMonday - now;
  };

  const getTimeComponents = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = React.useState(
    getTimeUntilNextMonday()
  );
  const [timeComponents, setTimeComponents] = React.useState(
    getTimeComponents(timeRemaining)
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prevTimeRemaining) => {
        if (prevTimeRemaining <= 1000) {
          // updateTop20List();
          return 7 * 24 * 3600 * 1000;
        }
        return prevTimeRemaining - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    setTimeComponents(getTimeComponents(timeRemaining));
  }, [timeRemaining]);
  const lastWeekDates = getLastWeekDates(date);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        margin: "0px !important",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <Box
        sx={{
          background: `url(${LogBackground3})`,
          backgroundRepeat: "no-repeat",
          textAlign: "center",
          marginTop: "-80px",
          paddingTop: "144px",
          paddingBottom: "70px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography
          className="faq-section-heading"
          sx={{
            fontSize: "36px",
            color: "#fff",
            fontWeight: "700",
          }}
        >
          Top Curator Chart
        </Typography>
      </Box>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: isSmallScreen ? "column-reverse" : "row",
          gap: "32px",
          justifyContent: "center",
          alignItems: isSmallScreen ? "center" : "flex-start",
          padding: "80px 24px !important",
        }}
      >
        <Grid
          item
          xs={isSmallScreen ? 12 : 8.37}
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: isSmallScreen ? "center" : "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              width: "100%",
              justifyContent: isSmallScreen ? "center" : "flex-start",
            }}
          >
            <Button
              onClick={handlePreviousDay}
              sx={{
                minWidth: smallScreen ? "38px" : "48px",
                height: smallScreen ? "38px" : "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                backgroundColor: "#00ADB5",
                padding: smallScreen ? "16px" : "18px !important",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#00ADB5",
                },
              }}
            >
              <LeftArrow />
            </Button>

            <CustomDatePicker
              width={271}
              placeholder="Select a date"
              fontFamily="Poppins-Regular"
              color="#00000080"
              fontSize="14px"
              value={date}
              onChange={handleDateChange}
              format="DD/MM/YYYY"
              helperText="Please select a valid date."
            />
            <Button
              onClick={handleNextDay}
              disabled={isNextDayDisabled}
              sx={{
                minWidth: smallScreen ? "38px" : "48px",
                height: smallScreen ? "38px" : "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                padding: smallScreen ? "16px" : "18px !important",
                backgroundColor: isNextDayDisabled ? "#EEEEEE" : "#00ADB5",
                border: "0.3px solid #D9D9D9",
                cursor: isNextDayDisabled ? "not-allowed" : "pointer",
                "& svg path": {
                  fill: isNextDayDisabled ? "#00000080" : "#ffffff !important",
                },
                "&:hover": {
                  backgroundColor: isNextDayDisabled ? "#D3D3D3" : "#00ADB5",
                },
              }}
            >
              <RightArrow />
            </Button>
          </Box>

          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: "700",
              color: "#00ADB5",
              marginTop: "24px",
              fontFamily: "Poppins-bold",
              textAlign: smallScreen ? "center" : "flex-start",
            }}
          >
            Top 20 curators
          </Typography>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#000000",
              marginTop: "12px",
              marginBottom: "32px",
              textTransform: "capitalize",
            }}
          >
            {lastWeekDates}
          </Typography>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#00000080",
            }}
          >
            The Top Curator chart highlights the most active curators on
            Pitchplaylists from the past week, based on the number of song
            submissions they've received and the feedback they've provided
            (either approving or declining songs). If you have added at least
            one playlist that you own to our platform, you are eligible to earn
            additional points by inviting other curators to our platform using
            your referral link, which is available in the "For Curators"
            dashboard section. Want to join and potentially get a weekly
            playlist promotion through free Meta social media ads? Sign up now
            for free!
          </Typography>

          <Box
            sx={{
              marginTop: "63px",
              width: "100%",
              border: "1px solid #000000",
              borderBottom: sortedData && sortedData.length >= 0 ? "0px" : "",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#00ADB5",
                fontSize: "36px",
                fontWeight: "700",
                color: "#ffffff",
                textAlign: "center",
                padding: "11px 0",
                borderBottom: "1px solid #000000",
              }}
            >
              Top 20
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
              }}
            >
              {sortedData && sortedData.length >= 0 ? (
                sortedData.map((value: any, index: any) => {
                  const responseRateString =
                    value?.responseRate?.$numberDecimal ?? "0";
                  const responseRateDecimal = parseFloat(responseRateString);
                  const responseRatePercentage = (
                    responseRateDecimal * 100
                  ).toFixed(2);
                  return (
                    <Box
                      key={index}
                      sx={{
                        backgroundColor:
                          value.position === 1 ? "#1DB954" : "#FFFFFF",
                        padding: "0px 24px",
                        borderBottom: "1px solid #000000",
                      }}
                    >
                      <Box
                        sx={{
                          padding: "32px 0",
                          paddingTop: "0px !important",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "16px",
                            flexDirection: smallScreen ? "column" : "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "16px",
                              justifyContent: smallScreen
                                ? "center"
                                : "flex-start",
                              flexWrap: "wrap",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginRight: "26px",
                                width: "50px",
                                paddingTop:
                                  value.position === 1 ? "46px" : "52px",
                              }}
                            >
                              <Typography
                                sx={{
                                  lineHeight:
                                    value.position === 1 ? "110px" : "48px",
                                  fontWeight: "700",
                                  color:
                                    value.position === 1
                                      ? "#FFFFFF"
                                      : "#00ADB5",
                                  fontSize:
                                    value.position === 1 ? "110px" : "48px",
                                }}
                              >
                                {value.position}
                              </Typography>
                              <Box
                                sx={{
                                  display: "block",
                                  "& svg path": {
                                    fill: value.position === 1 ? "#FFFFFF" : "",
                                  },
                                }}
                              >
                                {value.position === value.lastWeek ? (
                                  <EqualToIcon />
                                ) : value.position < value.lastWeek ? (
                                  <GreenArrow />
                                ) : value.position > value.lastWeek ? (
                                  <RedArrow />
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Box>
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "54px",
                                    width: "117px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={
                                      value.position === 1
                                        ? SpotifyWhiteLogo
                                        : SpotifyGreenLogo
                                    }
                                    alt=""
                                    style={{
                                      width: "90px",
                                      height: "27px",
                                    }}
                                  />
                                </Box>
                              </Box>
                              <img
                                src={
                                  value.userId?.image[1]?.url
                                    ? value.userId?.image[1]?.url
                                    : Avatar
                                }
                                alt=""
                                style={{
                                  width: "144px",
                                  height: "144px",
                                  objectFit: "cover",
                                  border: value.userId?.image[1]?.url
                                    ? ""
                                    : "2px solid #FFFFFF",
                                }}
                              />
                            </Box>
                            <Box sx={{ paddingTop: "48px" }}>
                              <MuiLink
                                component={Link}
                                to={value.userId?.profileUrl}
                                target="_blank"
                                sx={{
                                  fontSize: "24px",
                                  fontFamily: themeFonts["Poppins-ExtraBold"],
                                  color:
                                    value.position === 1
                                      ? "#FFFFFF"
                                      : "#000000",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                {value.userId?.name}
                              </MuiLink>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: themeFonts["Poppins-Medium"],
                                  color: "#000000",
                                }}
                              >
                                Last Week:{" "}
                                <Typography
                                  component={"span"}
                                  sx={{
                                    fontSize: "16px",
                                    fontFamily: themeFonts["Poppins-SemiBold"],
                                    color:
                                      value.position === 1
                                        ? "#FFFFFF"
                                        : "#00ADB5",
                                  }}
                                >
                                  {value.lastWeek}
                                </Typography>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: themeFonts["Poppins-Medium"],
                                  color: "#000000",
                                }}
                              >
                                Peak:{" "}
                                <Typography
                                  component={"span"}
                                  sx={{
                                    fontSize: "16px",
                                    fontFamily: themeFonts["Poppins-SemiBold"],
                                    color:
                                      value.position === 1
                                        ? "#FFFFFF"
                                        : "#00ADB5",
                                  }}
                                >
                                  {value.peak}
                                </Typography>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: themeFonts["Poppins-Medium"],
                                  color: "#000000",
                                }}
                              >
                                Weeks In Top Chart:{" "}
                                <Typography
                                  component={"span"}
                                  sx={{
                                    fontSize: "16px",
                                    fontFamily: themeFonts["Poppins-SemiBold"],
                                    color:
                                      value.position === 1
                                        ? "#FFFFFF"
                                        : "#00ADB5",
                                  }}
                                >
                                  {value.weekInTopChart}
                                </Typography>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: themeFonts["Poppins-Medium"],
                                  color: "#000000",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                Response Rate:
                                <Typography
                                  component={"span"}
                                  sx={{
                                    fontSize: "16px",
                                    fontFamily: themeFonts["Poppins-SemiBold"],
                                    color:
                                      value.position === 1
                                        ? "#FFFFFF"
                                        : "#00ADB5",
                                  }}
                                >
                                  {" "}
                                  {responseRatePercentage}%
                                </Typography>
                                <HtmlTooltip
                                  title={
                                    <Typography
                                      sx={{
                                        margin: "0px",
                                        fontFamily:
                                          themeFonts["Poppins-Medium"],
                                        fontSize: "13px",
                                        color: "#000000",
                                        lineHeight: "19.5px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      The response rate is determined by
                                      dividing the number of songs that have
                                      received your feedback (whether approved
                                      or declined) by the total number of songs
                                      received on all individual curator
                                      playlists.
                                    </Typography>
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <Button
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                      minWidth: "5px",
                                      minHeight: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  >
                                    <InformationIcon />
                                  </Button>
                                </HtmlTooltip>
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingTop: "55px",
                            }}
                          >
                            <Button
                              onClick={() => {
                                navigate(ROUTES.SUBMIT_A_SONG, {
                                  state: { userName: value.userId?.name },
                                });
                              }}
                              sx={{
                                padding: "20px 28px",
                                fontFamily: themeFonts["Poppins-ExtraBold"],
                                fontSize: "16px !important",
                                height: "45px",
                                color: "#ffffff",
                                backgroundColor:
                                  value.position === 1 ? "#191414" : "#00ADB5",
                                borderRadius: "33px",
                                "&:hover": {
                                  background:
                                    value.position === 1
                                      ? "#191414"
                                      : "#00ADB5",
                                },
                              }}
                            >
                              Submit a Song
                            </Button>
                            {value.position === 1 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "end",
                                  marginTop: smallScreen ? "16px" : "auto",
                                }}
                              >
                                <HtmlTooltip
                                  title={
                                    <Typography
                                      sx={{
                                        margin: "0px",
                                        fontFamily:
                                          themeFonts["Poppins-Medium"],
                                        fontSize: "13px",
                                        color: "#000000",
                                        lineHeight: "19.5px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      The winner will receive an email
                                      notification requesting further details.
                                      If we don't receive a response from the
                                      winner, we will pass the prize to the next
                                      recipient, and so on until we receive a
                                      response within 24 hours of our initial
                                      message.
                                    </Typography>
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <StyledButton>
                                    <KingIcon />
                                  </StyledButton>
                                </HtmlTooltip>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "200px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontFamily: themeFonts["Poppins-Medium"],
                      color: "#00000080",
                    }}
                  >
                    {" "}
                    The top 20 will be displayed once the first 20 curators join
                    our platform!
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={isSmallScreen ? 12 : isMediumScreen ? 3 : 1.95}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: isSmallScreen ? "row" : "column",
            maxWidth: isSmallScreen ? "100%" : "300px !important",
            gap: "32px",
            justifyContent: isSmallScreen ? "center" : "flex-start",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFCC33",
              padding: "24px 38px",
              textAlign: "center",
              width: "100%",
              maxWidth: "300px",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "700",
                color: "#000000",
              }}
            >
              NEW TOP 20 IN...
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: "16px",
                gap: "16px",
                justifyContent: "center",
              }}
            >
              {["Days", "Hours", "Min", "Sec"].map((label, index) => {
                const value = [
                  timeComponents.days,
                  timeComponents.hours,
                  timeComponents.minutes,
                  timeComponents.seconds,
                ][index];
                return (
                  <Box key={label}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "18px",
                        fontWeight: "700",
                        color: "#000000",
                        paddingRight: "16px",
                        height: "auto",
                        borderRight: index < 3 ? "1px solid #000000" : "none",
                      }}
                    >
                      {String(value).padStart(2, "0")}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "8px",
                        fontWeight: "500",
                        color: "#000",
                        marginTop: "9px",
                        paddingRight: "16px",
                      }}
                    >
                      {label}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#00ADB5",
              padding: "20px 18px",
              textAlign: "center",
              maxWidth: "300px",
            }}
          >
            <TrophyIcon />
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "700",
                color: "#ffffff",
              }}
            >
              Weekly prize
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#ffffff",
                marginTop: "16px",
              }}
            >
              Get a free week-long playlist promotion on our social media with
              Meta ads!
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#F0F0F0",
              padding: "20px 12px",
              maxWidth: "300px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#000",
              }}
            >
              Chance For All To Be Chosen
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "700",
                color: "#00000080",
              }}
            >
              Even if you're not ranked as the top curator on our chart, you
              still have an opportunity to be selected. We carefully assess all
              positions to ensure that curator activity and authentic playlists
              on our website receive meaningful promotion.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#1db954",
              padding: "20px 14px",
              textAlign: "center",
              width: "100%",
              maxWidth: "300px",
            }}
          >
            <Box
              sx={{
                maxWidth: "478px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#000000",
                  marginBottom: "16px",
                }}
              >
                Add Your Playlist
              </Typography>
              <SearchComponents
                searchTitle={"Insert your Spotify playlist link"}
                isEmpty={true}
                onSearchChange={handleSearchChange}
                isInvalid={isInvalid}
                value={searchInput}
              />
              <Button
                className="need-help-btn"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#00000080",
                  marginTop: "16px",
                  textAlign: "end  !important",
                  cursor: "pointer",
                  background: "transparent",
                  padding: "0px",
                  height: "auto",
                  display: "flex",
                  marginLeft: "auto",
                }}
              >
                Need Help?
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiPopover-paper": {
                    boxShadow: "4px 4px 4px 0px #0000001A",
                    border: "0.3px solid #00000033 !important",
                  },
                }}
              >
                <Box
                  sx={{
                    padding: "12px 12px 15px 12px",
                    minWidth: "251px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "38px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: themeFonts["Poppins-Bold"],
                        fontSize: "14px !important",
                        color: "#000000",
                      }}
                    >
                      Using Spotify Playlist Links
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <CloseIcon
                        sx={{
                          cursor: "pointer",
                          color: "#000000",
                        }}
                        onClick={handleClose}
                      ></CloseIcon>
                    </Box>
                  </Box>
                  <Box>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                        paddingTop: "8px !important",
                        paddingBottom: "3px !important",
                      }}
                    >
                      <ListItem
                        sx={{
                          padding: "0px 0px 19px 0px !important",
                        }}
                      >
                        <ListItemText
                          sx={{
                            "& span.MuiListItemText-primary": {
                              lineHeight: "15px",
                              display: "flex",
                              fontSize: "12px",
                              color: themeColors["#00000080"],
                              fontFamily: themeFonts["Poppins-Regular"],
                              alignItems: "center",
                            },
                          }}
                          primary={` 1.  Go to your playlist on Spotify`}
                        />
                      </ListItem>

                      <ListItem
                        sx={{
                          padding: "0px 0px 19px 0px !important",
                        }}
                      >
                        <ListItemText
                          sx={{
                            "& span.MuiListItemText-primary": {
                              lineHeight: "15px",
                              display: "flex",
                              fontSize: "12px",
                              color: themeColors["#00000080"],
                              fontFamily: themeFonts["Poppins-Regular"],
                              alignItems: "center",
                              gap: "5px",
                            },
                          }}
                        >
                          2. Click the{" "}
                          <GreenDot
                            style={{
                              boxSizing: "content-box",
                              cursor: "pointer",
                            }}
                          />
                          icon
                        </ListItemText>
                      </ListItem>
                      <ListItem
                        sx={{
                          padding: "0px 0px 19px 0px !important",
                        }}
                      >
                        <ListItemText
                          sx={{
                            "& span.MuiListItemText-primary": {
                              lineHeight: "15px",
                              display: "flex",
                              fontSize: "12px",
                              color: themeColors["#00000080"],
                              fontFamily: themeFonts["Poppins-Regular"],
                              alignItems: "center",
                              gap: "5px",
                            },
                          }}
                        >
                          3. Select{" "}
                          <Typography
                            sx={{
                              fontSize: "12px",
                              lineHeight: "15px",
                              color: themeColors["#00ADB5"],
                              fontFamily: themeFonts["Poppins-Regular"],
                              cursor: "pointer",
                            }}
                          >
                            Share
                          </Typography>{" "}
                          , then{" "}
                          <Typography
                            sx={{
                              fontSize: "12px",
                              lineHeight: "15px",
                              color: themeColors["#00ADB5"],
                              fontFamily: themeFonts["Poppins-Regular"],
                              cursor: "pointer",
                            }}
                          >
                            copy link to playlist
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem
                        sx={{
                          padding: "0px 0px 0px 0px !important",
                        }}
                      >
                        <ListItemText
                          sx={{
                            "& span.MuiListItemText-primary": {
                              lineHeight: "15px",
                              display: "flex",
                              fontSize: "12px",
                              color: themeColors["#00000080"],
                              fontFamily: themeFonts["Poppins-Regular"],
                              alignItems: "center",
                              paddingBottom: "0px !important",
                            },
                          }}
                          primary={` 4. Paste in your link to our submission bar`}
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Footer />
    </Grid>
  );
};

export default TopChart;
