import { Box, Link as MuiLink } from "@mui/material";
import {
  MainLogo,
  AuthLogo,
  // WhiteSecondLogo,
  // ColorSecondLogo,
} from "../../svgs";
import { Link } from "react-router-dom";
import { ROUTES } from "../consts/routes.consts";
export const NavbarMenuLeftLayout = ({
  open,
  isDashboardPage,
  isSmallScreen,
}: {
  open?: any;
  isDashboardPage?: any;
  isSmallScreen?: any;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 80,
        height: "inherit",
      }}
    >
      <MuiLink
        sx={{
          display: "flex",
        }}
        component={Link}
        to={ROUTES.HOME}
      >
        {/* {isDashboardPage && isSmallScreen ? (
        <ColorSecondLogo width={40}/>
        ) : isSmallScreen ? (
          <WhiteSecondLogo width={40}/>
        ) : isDashboardPage ? (
          <AuthLogo width={"220px"}/>
        ) : (
          <MainLogo width={"220px"}/>  
        )} */}
        {isDashboardPage && isSmallScreen ? (
          <AuthLogo width={"180px"} />
        ) : isSmallScreen ? (
          <MainLogo width={"180px"} />
        ) : isDashboardPage ? (
          <AuthLogo width={"220px"} />
        ) : (
          <MainLogo width={"220px"} />
        )}
      </MuiLink>
    </Box>
  );
};
