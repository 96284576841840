import { Box, Chip, Grid, Typography, useMediaQuery } from "@mui/material";
import { ChipImg, LogBackground } from "../../pngs";
import { HomePageSection } from "./home-page-section";
import { themeFonts } from "../../configs";
import { AwardIcon } from "../../svgs";
import { PopularPlayList } from "./popular-playlist";
import { Features } from "./features";
import { PointOfViewSection } from "./point-of-views/point-of-views-section";
import { Footer } from "../footer";

export const Home = () => {
  const xxs = useMediaQuery("(max-width:745px)");
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          margin: "0px !important",
          flexDirection: "column",
          width: "100% !important",
        }}
      >
        <Box
          sx={{
            background: `url(${LogBackground})`,
            backgroundSize: "cover",
            minHeight: xxs ? "auto" : "660px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginTop: "-80px",
          }}
        >
          <Box
            sx={{
              paddingTop: "80px",
            }}
          >
            <HomePageSection />
          </Box>
        </Box>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "center",
            padding: "24px !important",
          }}
        >
          <Chip
            label="100k+ Artists & Curators Trust PitchPlaylists"
            sx={{
              color: "#FFFFFF",
              fontSize: "18px",
              fontFamily: themeFonts["Poppins-Medium"],
              background: `url(${ChipImg})`,
              height: "35px",
              paddingX: "8px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
          <Typography
            sx={{
              color: "#000000",
              fontSize: "32px",
              fontFamily: themeFonts["Poppins-SemiBold"],
              marginTop: "16px",
              textAlign: "center",
            }}
          >
            Weekly free playlist promotion prize
          </Typography>
          <Box
            sx={{
              marginTop: "32px",
            }}
          >
            <AwardIcon />
          </Box>
          <Typography
            sx={{
              color: "#00000099",
              fontSize: "16px",
              fontFamily: themeFonts["Poppins-Regular"],
              marginTop: "16px",
              maxWidth: 580,
              textAlign: "center",
              marginBottom: "80px",
            }}
          >
            Get free promotion from us to help your playlist grow through our
            social media ads, ensuring organic growth!
          </Typography>
        </Grid>
        <PopularPlayList/>
        <Features />
        <PointOfViewSection/>
        <Footer/>
      </Grid>
    </>
  );
};
