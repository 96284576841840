import { Box, Chip, Grid, Typography, useMediaQuery } from "@mui/material";
import { themeColors, themeFonts } from "../../../configs";
import { Image1, Image2, Image3 } from "../../../pngs";

export const ArtistPointOfView = ({
  isMiniScreen,
  stickyTabs,
  isMidiScreen,
}: {
  isMiniScreen?: any;
  stickyTabs?: any;
  isMidiScreen?: any;
}) => {
  const isMediumScreen = useMediaQuery("(max-width:1152px)");
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          paddingLeft: "0 !important",
          paddingTop:
            stickyTabs && isMidiScreen
              ? "50px !important"
              : isMediumScreen
              ? "50px"
              : "80px !important",
          gap: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMediumScreen ? "column-reverse" : "row",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: isMediumScreen ? "30px" : "166.38px",
            alignItems: "center",
          }}
        >
          <img src={Image1} alt="" className="point-of-view-section-image" />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Chip
              label="Submit your song to curator playlist"
              sx={{
                color: themeColors["#00ADB5"],
                fontSize: "18px",
                fontFamily: themeFonts["Poppins-Medium"],
                background: themeColors["#00ADB526"],
                paddingY: "6px",
                paddingX: "19px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                whiteSpace: "normal",
                height: "fit-content",
                textAlign: "center",
                borderRadius: "50px",
                "& span.MuiChip-label": {
                  whiteSpace: "normal",
                },
              }}
            />
            <Typography
              sx={{
                color: themeColors["#FFFFFF"],
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                maxWidth: 486,
                textAlign: isMiniScreen ? "center" : "justify",
              }}
            >
              Quickly share your music with major Spotify curators in seconds.
              Our playlist filtering makes finding the perfect match easy.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMediumScreen ? "column" : "row",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: isMediumScreen ? "30px" : "165.9px",
            alignItems: "center",
            marginTop: isMediumScreen ? "60px" : "0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Chip
              label="Artist dashboard"
              sx={{
                color: themeColors["#00ADB5"],
                fontSize: "18px",
                fontFamily: themeFonts["Poppins-Medium"],
                background: themeColors["#00ADB526"],
                paddingY: "6px",
                paddingX: "19px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                whiteSpace: "normal",
                height: "fit-content",
                textAlign: "center",
                borderRadius: "50px",
                "& span.MuiChip-label": {
                  whiteSpace: "normal",
                },
              }}
            />
            <Typography
              sx={{
                color: themeColors["#FFFFFF"],
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                maxWidth: 486,
                textAlign: isMiniScreen ? "center" : "justify",
              }}
            >
              Use your PitchPlaylist dashboard to manage artists, review
              submissions, and gain valuable campaign insights!
            </Typography>
          </Box>
          <img src={Image2} alt="" className="point-of-view-section-image" />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMediumScreen ? "column-reverse" : "row",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: isMediumScreen ? "30px" : "167.1px",
            alignItems: "center",
            marginTop: isMediumScreen ? "60px" : "0px",
          }}
        >
          <img src={Image3} alt="" className="point-of-view-section-image" />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Chip
              label="Get 20 free credits every week!"
              sx={{
                color: themeColors["#00ADB5"],
                fontSize: "18px",
                fontFamily: themeFonts["Poppins-Medium"],
                background: themeColors["#00ADB526"],
                paddingY: "6px",
                paddingX: "19px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                whiteSpace: "normal",
                height: "fit-content",
                textAlign: "center",
                borderRadius: "50px",
                "& span.MuiChip-label": {
                  whiteSpace: "normal",
                },
              }}
            />
            <Typography
              sx={{
                color: themeColors["#FFFFFF"],
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                maxWidth: 486,
                textAlign: isMiniScreen ? "center" : "justify",
              }}
            >
              Receive 20 free credits every week to submit your tracks to
              curators and their playlists at no cost. Just sign in weekly, and
              your credits will be topped up!
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
