import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    userVisit: builder.mutation<any, UserApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/other/user-visit`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
    deleteUsers: builder.mutation<any, UserApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/user/delete-user`,
          method: "DELETE",
          body: queryArg.userId,
        };
      },
    }),
  }),
});
export { injectedRtkApi as enhancedApi };
export type UserApiArg = {
  id?: any;
  userId?: any;
};
export const { useUserVisitMutation, useDeleteUsersMutation } = injectedRtkApi;
