import { createTheme, ThemeOptions } from "@mui/material";

export const themeColors = {
  "#000000": "#000000",
  "#055160": "#055160",
  "#12B76A": "#12B76A",
  "#189A64": "#189A64",
  "#0F8815": "#0F8815",
  "#1B3850": "#1B3850",
  "#E2564C": "#E2564C",
  "#264E6F": "#264E6F",
  "#17A120": "#17A120",
  "#1B385B": "#1B385B",
  "#FF0000": "#FF0000",
  "#8BC34A": "#8BC34A",
  "#007CFF": "#007CFF",
  "#F5F9FF": "#F5F9FF",
  "#DE5544": "#DE5544",
  "#2D87C2": "#2D87C2",
  "#BFBFBF": "#BFBFBF",
  "#627AC7": "#627AC7",
  "#0C345D": "#0C345D",
  "#BEDEFF": "#BEDEFF",
  "#F5FAFF": "#F5FAFF",
  "#3551AB": "#3551AB",
  "#E1E1E1": "#E1E1E1",
  "#BABABA": "#BABABA",
  "#2E3032": "#2E3032",
  "#28AAE1": "#28AAE1",
  "#383838": "#383838",
  "#026D9D": "#026D9D",
  "#3660A0": "#3660A0",
  "#4FB030": "#4FB030",
  "#D4DBFF": "#D4DBFF",
  "#F5F5F5": "#F5F5F5",
  "#EA7702": "#EA7702",
  "#EA9136": "#EA9136",
  "#2F353B": "#2F353B",
  "#171616": "#171616",
  "#00C51A": "#00C51A",
  "#D6FFD8": "#D6FFD8",
  "#011EAF": "#011EAF",
  "#FFD6D6": "#FFD6D6",
  "#323B4B": "#323B4B",
  "#101530": "#101530",
  "#FF3939": "#FF3939",
  "#1C223E": "#1C223E",
  "#346B98": "#346B98",
  "#333333": "#333333",
  "#F2F2F2": "#F2F2F2",
  "#A8A8A8": "#A8A8A8",
  "#4CE26A": "#4CE26A",
  "#FF8000": "#FF8000",
  "#D4D4D4": "#D4D4D4",
  "#0F1533": "#0F1533",
  "#707070": "#707070",
  "#34C759": "#34C759",
  "#38CB89": "#38CB89",
  "#FFEBD8": "#FFEBD8",
  "#E1F4FC": "#E1F4FC",
  "#E8E8E8": "#E8E8E8",
  "#4588C0": "#4588C0",
  "#4E5D78": "#4E5D78",
  "#5C5E61": "#5C5E61",
  "#616161": "#616161",
  "#EA4335": "#EA4335",
  "#48494C": "#48494C",
  "#87B3D6": "#87B3D6",
  "#6F88A7": "#6F88A7",
  "#8A94A6": "#8A94A6",
  "#A2C4DF": "#A2C4DF",
  "#ADB4BD": "#ADB4BD",
  "#303134": "#303134",
  "#FFF7E6": "#FFF7E6",
  "#B0B7C3": "#B0B7C3",
  "#BCBEC0": "#BCBEC0",
  "#CADDED": "#CADDED",
  "#D9DDE3": "#D9DDE3",
  "#DFE2E7": "#DFE2E7",
  "#D61A02": "#D61A02",
  "#E11900": "#E11900",
  "#FC9C3C": "#FC9C3C",
  "#E2F4EC": "#E2F4EC",
  "#E5F4F9": "#E5F4F9",
  "#E5F6EF": "#E5F6EF",
  "#E9EEF2": "#E9EEF2",
  "#ECE8E8": "#ECE8E8",
  "#EEF5F9": "#EEF5F9",
  "#E3EFF9": "#E3EFF9",
  "#E4E4E6": "#E4E4E6",
  "#EFF1F3": "#EFF1F3",
  "#F2F7FA": "#F2F7FA",
  "#F94242": "#F94242",
  "#F3F1FC": "#F3F1FC",
  "#F5F7FA": "#F5F7FA",
  "#F79009": "#F79009",
  "#F7F1E3": "#F7F1E3",
  "#F7F8F9": "#F7F8F9",
  "#FAFBFC": "#FAFBFC",
  "#FE9549": "#FE9549",
  "#FEDF89": "#FEDF89",
  "#FF6A55": "#FF6A55",
  "#FFFAEB": "#FFFAEB",
  "#FFFFFF": "#FFFFFF",
  "#E9E9EA": "#E9E9EA",
  "#FDE7E6": "#FDE7E6",
  "#C90000": "#C90000",
  "#FFF6EE": "#FFF6EE",
  "#224C78": "#224C78",
  "#CF30CA": "#CF30CA",
  "#55A232": "#55A232",
  "#66FFBD": "#66FFBD",
  "rgba(5, 255, 0, 0.05)": "rgba(5, 255, 0, 0.05)",
  "rgb(242 242 242 / 10%)": "rgb(242 242 242 / 10%)",
  "rgb(54 96 160 / 14%)": "rgb(54 96 160 / 14%)",
  "rgb(222 85 68 / 14%)": "rgb(222 85 68 / 14%)",
  "rgb(2 109 157 / 14%)": "rgb(2 109 157 / 14%)",
  "rgb(40 170 225 / 14%)": "rgb(40 170 225 / 14%)",
  "rgb(0 0 0 / 40%)": "rgb(0 0 0 / 40%)",
  "rgb(128 199 98 / 31%)": "rgb(128 199 98 / 31%)",
  "rgb(98 111 199 / 31%)": "rgb(98 111 199 / 31%)",
  "rgb(199 98 98 / 31%)": "rgb(199 98 98 / 31%)",
  "rgb(0 0 0 / 50%)": "rgb(0 0 0 / 50%)",
  "rgb(0 0 0 / 100%)": "rgb(0 0 0 / 100%)",
  "rgb(255 255 255 / 60%)": "rgb(255 255 255 / 60%)",
  "#092ACC": "#092ACC",
  "#FFF2F2": "#FFF2F2",
  "#D7E0ED": "#D7E0ED",
  "#96BCDB": "#96BCDB",
  "#F6C863": "#F6C863",
  "#EF5261": "#EF5261",
  "#FF3C38": "#FF3C38",
  "#42971B": "#42971B",
  "#971B1B": "#971B1B",
  "#0A2A4A": "#0A2A4A",
  "#949494": "#949494",
  "#2980BA": "#2980BA",
  "#C28111": "#C28111",
  "#D7D9DB": "#D7D9DB",
  "#808080": "#808080",
  "#27AE60": "#27AE60",
  "#7B7B7B": "#7B7B7B",
  "#279CAE": "#279CAE",
  "#DBEAF1": "#DBEAF1",
  "#8F8F8F": "#8F8F8F",
  "#7FBF65": "#7FBF65",
  "#737373": "#737373",
  "#1B64B8": "#1B64B8",
  "#2B468B": "#2B468B",
  "#8B2B5B": "#8B2B5B",
  "#7E7E7E": "#7E7E7E",
  "#9E9E9E": "#9E9E9E",
  "#1966D9": "#1966D9",
  "#E1F5FF": "#E1F5FF",
  "#29A5B8": "#29A5B8",
  "#E9FFEE": "#E9FFEE",
  "#FFE9E9": "#FFE9E9",
  "#D8EBFF": "#D8EBFF",
  "#646464": "#646464",
  "#1B1B97": "#1B1B97",
  "#509CEE": "#509CEE",
  "#EFF8FF": "#EFF8FF",
  "#269CFF": "#269CFF",
  "#AFAFAF": "#AFAFAF",
  "#3F1F71": "#3F1F71",
  "#878787": "#878787",
  "#EFEFEF": "#EFEFEF",
  "#00ADB5": "#00ADB5",
  "#F0F0F0": "#F0F0F0",
  "#FFCC33": "#FFCC33",
  "#2CA9BC": "#2CA9BC",
  "#00000080": "#00000080",
  "#00000082": "#00000082",
  "#EEEEEE99": "#EEEEEE99",
  "#00000066": "#00000066",
  "#000000B2": "#000000B2",
  "#00ADB526": "#00ADB526",
  "#00000099": "#00000099",
  "#D9D9D980": "#D9D9D980",
  "#0000004D": "#0000004D",
  "rgb(98 128 199 / 31%)": "rgb(98 128 199 / 31%)",
  "rgb(241 241 241 / 55%)": "rgb(241 241 241 / 55%)",
  "rgb(255 255 255 / 50%)": "rgb(255 255 255 / 50%)",
};

export const themeFonts = {
  "Poppins-Regular": "Poppins-Regular",
  "Poppins-Bold": "Poppins-Bold",
  "Poppins-Medium": "Poppins-Medium",
  "Poppins-SemiBold": "Poppins-SemiBold",
  "Poppins-ExtraBold": "Poppins-ExtraBold",
};

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        fontFamily: themeFonts["Poppins-Bold"],
        boxShadow: "none",

        "&:hover": {
          boxShadow: "none",
        },
      },
      sizeMedium: {
        fontSize: 14,
        borderRadius: 6,
        height: 50,
        paddingLeft: 24,
        paddingRight: 24,
      },
      sizeSmall: {
        fontSize: 12,
        borderRadius: 6,
        height: 36,
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 6,
      },
    },
  },
};

const palette = {
  primary: {
    main: themeColors["#4588C0"],
  },
  success: {
    main: themeColors["#12B76A"],
  },
  error: {
    main: themeColors["#FF6A55"],
    contrastText: themeColors["#FFFFFF"],
  },
  text: {
    primary: themeColors["#2F353B"],
  },
  action: {
    hoverOpacity: 0.1,
  },
};

const typography = {
  fontFamily: [themeFonts["Poppins-Medium"]].join(","),
  fontWeightRegular: 500,
};

export const theme = createTheme({
  components,
  palette,
  typography,
});
