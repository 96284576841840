import {
  Box,
  Dialog,
  Link as MuiLink,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { themeColors, themeFonts } from "../../configs";
import { ROUTES } from "../consts/routes.consts";
import { useNavigate } from "react-router-dom";

export const SubmitMenu = ({
  open,
  handleClose,
  anchorEl,
}: {
  open: any;
  handleClose: any;
  anchorEl: any;
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <Box sx={{ float: "right" }}>
          <Dialog onClose={handleClose} open={open}>
            <Box>
              <Menu
                id="isLive"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "menu-button",
                }}
                sx={{
                  "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper":
                    {
                      top: "65px !important",
                      borderRadius: "6px !important",
                    },
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate(ROUTES.SUBMIT_A_SONG);
                    handleClose();
                  }}
                >
                  <MuiLink
                    sx={{
                      color: themeColors["#00000099"],
                      fontSize: "12px",
                      fontFamily: themeFonts["Poppins-Regular"],
                      textDecoration: "none",
                     
                    }}
                  >
                    Submit a song
                  </MuiLink>
                </MenuItem>
                <Divider sx={{ width: "100%", margin: "0px !important" }} />
                <MenuItem
                  onClick={() => {
                    navigate(ROUTES.SUBMIT_A_PLAYLIST);
                    handleClose();
                  }}
                >
                  <MuiLink
                    sx={{
                      color: themeColors["#00000099"],
                      fontSize: "12px",
                      fontFamily: themeFonts["Poppins-Regular"],
                      textDecoration: "none",
                    }}
                  >
                    Submit a playlist
                  </MuiLink>
                </MenuItem>
              </Menu>
            </Box>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};
