import { emptySplitApi as api } from "./emptyApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateGates: builder.mutation<any, Partial<GenresApiArg>>({
      query: (queryArg) => {
        return {
          url: `/api/playlist/update-gates`,
          method: "PUT",
          body: queryArg,
        };
      },
    }),
  }),
});
export type GenresApiArg = {
  playlistId?: any;
  submitConditions?: {
    followUserUrls?: any;
    followPlaylistUrls?: any;
    platformType?: String;
    saveTrackUrl?: String;
  };
};

export { injectedRtkApi as enhancedApi };

export const { useUpdateGatesMutation } = injectedRtkApi;
