import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTopCuratorsList: builder.query<any, void>({
      query: () => {
        return {
          url: `/api/playlist/top-curator`,
        };
      },
    }),
    getCalendarValue: builder.query<[], Partial<CalendarValueArg>>({
      query: (queryArg) => {
        return {
          url: `/api/playlist/top-curator`,
          params: queryArg,
        };
      },
    }),
  }),
});


export { injectedRtkApi as enhancedApi };
export type CalendarValueArg = { date?: string };

export const { useGetTopCuratorsListQuery, useGetCalendarValueQuery } = injectedRtkApi;
