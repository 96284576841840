import { Box, Button } from "@mui/material";
import { SearchFilledInput } from "./search-filled-input";


export const SearchComponents = ({
  searchTitle,
  Component,
  isEmpty,
  hideDate = false,
  onSearchChange,
  isInvalid,
  homepageSection,
  value,showIcon
}: {
  searchTitle: string;
  Component?: () => JSX.Element;
  isEmpty: boolean;
  hideDate?: boolean;
  onSearchChange?: any;
  isInvalid?: any;
  homepageSection?: any;
  value?: any;showIcon?:any
}) => {
  return (
    <Box
      sx={{
        marginLeft: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: homepageSection ? "center" : "flex-end",
        flex: 1,
      }}
    >
      {!isEmpty && (
        <Button size="small" sx={{ position: "relative", marginRight: "20px" }}>
          Clear Filters
        </Button>
      )}
      <Box
        sx={{
          maxWidth: 478,
          width: "100%",
        }}
      >
        <SearchFilledInput
          variant="filled"
          placeholder={searchTitle}
          value={value}
          onChange={(event: any) => onSearchChange(event.target.value)}
          height="48px"
          isInvalid={isInvalid}showIcon={showIcon}
        />
      </Box>

      {Component && <Component />}
    </Box>
  );
};
