import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { themeFonts } from '../../../configs';

function createData(
  name: string,
  purpose: string,
  dataCollection: string,
  dataSharing: string,
) {
  return { name, purpose, dataCollection, dataSharing };
}

const rows = [
  createData('AWS', "Cloud computing services", "Limited, respecting privacy (e.g., user analytics)", "No public sharing"),
  createData('Stripe', "Payment processing service", "Limited to necessary payment information", "No public sharing"),
  createData('Spotify', "Music streaming and playlist data", "Limited to necessary user and playlist information. We also manage artist profile activities required by curators before song submissions, such as saving songs, following playlists, and following users or artists.", "No public sharing"),
];

export default function PrivacySectionTable() {
  return (
    <TableContainer component={Paper} 
    sx={{
        "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1":{
          boxShadow:"0",
          borderRadius:"0px",
        }
    }}
    >
      <Table sx={{ width:"100%",}} aria-label="simple table">
        <TableHead>
          <TableRow sx={{background: "#EFEFEF", color: "#000000 !important",fontSize: "13px", fontFamily: themeFonts["Poppins-Regular"],}}>
            <TableCell sx={{border:"0.5px solid #00000040", textAlign:"center",color: "#000000 !important",fontFamily: themeFonts["Poppins-Regular"],fontSize:"13px" }}>Plugin/Service Name</TableCell>
            <TableCell sx={{border:"0.5px solid #00000040", textAlign:"center",color: "#000000 !important",fontFamily: themeFonts["Poppins-Regular"], fontSize:"13px"}}>Purpose</TableCell>
            <TableCell sx={{border:"0.5px solid #00000040", textAlign:"center",color: "#000000 !important",fontFamily: themeFonts["Poppins-Regular"], fontSize:"13px"}}>Data Collection</TableCell>
            <TableCell sx={{border:"0.5px solid #00000040", textAlign:"center",color: "#000000 !important",fontFamily: themeFonts["Poppins-Regular"],fontSize:"13px"}}>Data Sharing</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ }}
            >
              <TableCell component="th" scope="row" sx={{border:"0.5px solid #00000040", textAlign:"center", color: "#00000080 !important",fontFamily: themeFonts["Poppins-Regular"],fontSize:"12px"}}>
                {row.name}
              </TableCell>
              <TableCell sx={{border:"0.5px solid #00000040", textAlign:"center",color: "#00000080 !important",fontFamily: themeFonts["Poppins-Regular"],fontSize:"12px"}}>{row.purpose}</TableCell>
              <TableCell sx={{border:"0.5px solid #00000040", textAlign:"center",color: "#00000080 !important",fontFamily: themeFonts["Poppins-Regular"],fontSize:"12px"}} >{row.dataCollection}</TableCell>
              <TableCell sx={{border:"0.5px solid #00000040", textAlign:"center",color: "#00000080 !important",fontFamily: themeFonts["Poppins-Regular"],fontSize:"12px"}}>{row.dataSharing}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
